import { AccountHeader } from '../../../components/AccountHeader/AccountHeader'
import './MobileTransactionHistory.scss'
import mobileTransactionFilter from '../../../assets/mobileTransactionFilter.svg'
import { TransactionTable } from '../../../components/TransactionTable/TransactionTable'
import { PaginationComponent } from '../../../components/PaginationComponent/PaginationComponent'
import blueExport from '../../../assets/blueExport.svg'
import MobileTransactionFilter from './components/MobileTransactionFilter/MobileTransactionFilter'
import { IMobileTransactionHistoryProps } from './interfaces/IMobileTransactionHistoryProps'
import emptyTransactions from '../../../assets/emptyTransactions.svg'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { useTransactionHistoryContext } from '../context/TransactionHistoryContext'
import { useEffect } from 'react'
import warning from "../../../assets/warning.svg"
import CurrentBalanceHeader from '../../../shared/digitalbank/CurrentBalanceHeader/CurrentBalanceHeader'


/**Renders the mobile version of the transaction history page */
const MobileTransactionHistory = (props: IMobileTransactionHistoryProps) => {
    const { fromSavings } = props
    const {
        selectedTransaction,
        displayDetails,
        setDisplayDetails,
        startIndex,
        setStartIndex,
        endIndex,
        setEndIndex,
        filteredTransactions, setFilteredTransactions, handleExportToCSV, mobileFilterOpen, setMobileFilterOpen, numberOfItemsToShow, setNumberOfItemsToShow, transactionsLoaded, selectedAccount, transactions, typeFilter, dateFilter, amountFilter } = useTransactionHistoryContext()
    const transactionLoaded = useAppSelector((state: RootState) => state.transactions.transactionsLoaded)

    return (
        <>
            {transactionLoaded !== "Loading" ?
                <div className="content-wrap">
                    <div className="mobile-transaction-history-wrapper">
                        {!fromSavings &&
                            <div className="account-header-wrap">
                                <AccountHeader returnMessage="Account Details" setDisplayDetails={false} />
                                <CurrentBalanceHeader />
                            </div>
                        }
                        <div className={!fromSavings ? "transaction-history-card-wrap" : "transaction-history-card-wrap savings"}>
                            {(transactions.length > 0 && selectedAccount.isTransactionsLoaded) &&
                                <div className="header-row">
                                    <span className="transactions-text h4">Transactions</span>
                                    {transactionsLoaded === "Success" &&
                                        <div className="filter-box" onClick={() => setMobileFilterOpen(true)}>
                                            <img className="filter-image" src={mobileTransactionFilter} alt="filters" />
                                            <span className="filter-text regular">Filters</span>
                                        </div>
                                    }
                                </div>
                            }
                            <div className='transaction-table-wrap'>
                                {transactionLoaded !== "Failed" && 
                                    <>
                                        {filteredTransactions.length > 0 ?
                                            <TransactionTable
                                                transactions={filteredTransactions}
                                                startIndex={startIndex}
                                                endIndex={endIndex}
                                                selectedTransaction={selectedTransaction}
                                                displayDetails={displayDetails}
                                                setDisplayDetails={setDisplayDetails}
                                                isSortable={true}
                                                setFilteredTransactions={setFilteredTransactions}
                                            />
                                            :
                                            <>
                                                {selectedAccount.isTransactionsLoaded && transactionsLoaded === "Success" &&
                                                    <div className="empty-state-wrap">
                                                        <img className="empty-image" src={emptyTransactions} alt="no transactions" />
                                                        <span className="text mobileh4">No transactions to display</span>
                                                        <span className="text small">No transactions to display here yet. Future transactions will appear here</span>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {transactionsLoaded === "Failed" && 
                                <div className="Transactions-History-API-Error-Mobile">
                                    <div className="API-Error-Message-Container-Mobile">
                                        <img className="Image" src={warning} alt="Error Message" />
                                        <span className="Top-Text bold-x-large">Unable to load data</span>
                                        <span className="Bottom-Text regular">Unable to load data. Please wait a moment and try again.</span>
                                    </div>
                                </div> 
                                }
                            </div>
                            {transactionsLoaded === "Success" &&
                                <>
                                    {(filteredTransactions && filteredTransactions.length > 10) &&
                                        <div className="pagination-wrapper">
                                            <PaginationComponent
                                                type="Mobile"
                                                itemType=""
                                                totalItems={filteredTransactions.length}
                                                setStartIndex={setStartIndex}
                                                setEndIndex={setEndIndex}
                                                numberOfItemsToShow={numberOfItemsToShow}
                                                setNumberOfItemsToShow={setNumberOfItemsToShow}
                                            />
                                        </div>
                                    }
                                    {filteredTransactions.length > 0 &&
                                        <div className="export-center">
                                            <div className="export-wrapper" onClick={() => handleExportToCSV()}>
                                                <span className="export-text regular">{fromSavings ? "Export to CSV" : "Download to CSV"}</span>
                                                <img src={blueExport} alt="export to csv" />
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        {mobileFilterOpen &&
                            <>
                                <div className="overlay" />
                                <MobileTransactionFilter
                                    setMobileFilterOpen={setMobileFilterOpen}

                                />
                            </>
                        }
                    </div>
                </div>
                :
                <div className="mobile-transactions-loading-box-wrapper">
                    <div className="mobile-transaction-loading-box" />
                </div>
            }
        </>
    )
}

export default MobileTransactionHistory