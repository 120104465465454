import { createContext, useContext, useRef, useState } from "react";
import { IToastContextProps } from "./interfaces/IToastContextProps";
import { TOAST_TYPE } from '../../shared/digitalbank/ToastMessage/IToastMessageDBProps.types'
import { ToastMessageDB } from '../../shared/digitalbank/ToastMessage/ToastMessage';
import { useIsMounted } from '../../useHooks/useIsMounted/useIsMounted';


const DEFAULT_TIMEOUT = 4000
/**The ToastContext for handling all things related to the toast message*/
export const ToastContext = createContext<IToastContextProps>({
    type: "SUCCESS",
    setType: () => { },
    timeout: DEFAULT_TIMEOUT,
    setTimeout: () => { },
    message: "",
    setMessage: () => { },
})

/**The provider for the toast context, this must wrap all children components wherever it is used */
export const ToastContextProvider = (props: any) => {
    const [timeout, setTimeout] = useState<number>(DEFAULT_TIMEOUT)
    const [message, setMessage] = useState<string>("")
    const [type, setType] = useState<keyof typeof TOAST_TYPE>("SUCCESS")

    return (
        <ToastContext.Provider value={{
            type,
            setType,
            timeout,
            setTimeout,
            message,
            setMessage,
        }}>
            <>
                {message !== "" && <ToastMessageDB type={type} message={message} />}
            </>
            {props.children}
        </ToastContext.Provider>
    )
}

/**A custom use hook for the toast context provider, this can be called within any component */
export const useToastMessage = () => {
    const context = useContext(ToastContext);
    const isMounted = useIsMounted();
    const getTransferParams = useRef<{offset: number, size: number, sort: string, orderDirection: string}>({
        offset: 0, size: 10, sort: "", orderDirection: ""    
    })

    /**Sets the toast message type and message states */
    const setToastMessage = (type: keyof typeof TOAST_TYPE, message: string) => {
        context.setMessage(message)
        context.setType(type)
    }

    /**Resets the toast message */
    const resetToast = () => {
        setTimeout(() => {
            if (isMounted()) { //this is not working correctly for things like deletions with a modal
                context.setMessage("")
            }
            else{
                context.setMessage("")
            }
        }, DEFAULT_TIMEOUT)
    }

    /**Immediately clears the Toast Message */
    const clearToast = () => {
        context.setMessage("")
    }

    /**Updates the getTransferParams object */
    const updateGetTransferParams = (offset: number, size: number, sort: string, orderDirection: string) => {
        getTransferParams.current.offset = offset
        getTransferParams.current.size = size
        getTransferParams.current.sort = sort
        getTransferParams.current.orderDirection = orderDirection
    }

    /**Reset getTrasnerParms back to default */
    const resetGetTransferParamsToDefault = () => {
        getTransferParams.current.offset = 0
        getTransferParams.current.size = 10
        getTransferParams.current.sort = "date" 
        getTransferParams.current.orderDirection = "DESC"
    }

    return {
        type: context.type,
        setType: context.setType,
        timeout: context.timeout,
        setTimeout: context.setTimeout,
        message: context.message,
        setMessage: context.setMessage,
        onClick: context.onClick,
        resetToast: resetToast,
        setToastMessage: setToastMessage,
        clearToast: clearToast,
        getTransferParams: getTransferParams,
        updateGetTransferParams: updateGetTransferParams,
        resetGetTransferParamsToDefault: resetGetTransferParamsToDefault
    }
}