import axios from 'axios';
import { WindowStateContextProvider } from './Context/AccountContext/useWindowState';
import { Routes } from './Routes';
import { bffBaseUrl, getConfig, token } from './utils/globalVariables';
import TimeoutModal from './components/TimeoutModal/TimeoutModal';
import RequiredConsentModal from './components/RequiredConsentModal/RequiredConsentModal';
import { ToastContextProvider } from './Context/ToastContext/useToastContext';
import OTPModal from './components/OTPModal/OTPModal';
import { NotificationProvider } from './Context/NotificationContext/useNotificationContext';
import { loadAdobeScript } from './utils/loadAdobeScript';
import { useEffect, useState } from 'react';
import { loadGlassboxScript } from './utils/loadGlassboxScript';
import { isBothCustomer, isOnlyCreditCardCustomer } from './Pages/ReroutePage/ReroutePage';
import { useHistory } from 'react-router-dom';
import { OpenNewAccountContextProvider } from './Context/OpenNewAccountContext/useOpenNewAccountContext';
import { IRejectedResponse, MockAxios } from './utils/mockAxios';
import { MockDigitalBankAPIs } from './testUtils/utils/mockDigitalBankAPIs';
import { allAccountsAlreadyLinkedContext, allAccountsCreatedContext, selectionNeededFailureOnCreateContext, selectionNeededNoFailuresContext, someAccountRequireReviewContext, someAccountsFailedToCreateContext } from './Pages/TestYodlee/TestYodlee';
import { createRejectResponse } from './testUtils/utils/createRejectResponses';
 
loadAdobeScript();
loadGlassboxScript();

// export const createDefaultContext = () => {
//   let context = new MockDigitalBankAPIs();

//   // * Creates an account
//   // * The addAccount function returns the newly created account
//   // * You would only overload any values that you'll need to test for.
//   const ACCOUNT = context.addAccount({
//     accountId: '2222',
//     accountNickName: 'Go Blue',
//     accountTitle: 'Go Blue',
//   })
//   // const ACCOUNT2 = context.addAccount({
//   //   accountId: '1776',
//   //   accountNickName: 'testing',
//   //   accountTitle: 'testing',
//   //   accountType: "Savings",
//   //   status: "Closed",
//   //   closedDate: String(new Date())
//   // })
//   // context.addGracePeriodCDAccount()

//   context.addGracePeriodCDAccount()

//   context.addDocument('123', 'TAX', {
//     date: 'Jul 11, 2023',
//     documentTitle: "2023 Form 1099-INT (PDF)"
//   });
 
//   // context.addRequiredConsent("2222")
//   // context.addRequiredConsent("1776")
//   // context.addExternalAccount()

//   context.addBumpRateCDAccount()
 
//   context.addScheduledTransfers("1111", {}, {}, {isInitialFund: true})
 
//   // * Adds a person beneficary to the new account created.
//   context.addPersonBeneficiary(ACCOUNT.accountId as string, { id: '21087e47-5c33-4aa1-8e5f-b555ed818dc7', percentage: 50 });

//   context.addTrustBeneficiary(ACCOUNT.accountId as string, {id: "1234", percentage: 40})
//   context.addCharityBeneficiary(ACCOUNT.accountId as string, {id: "2345", percentage: 10})
 
//   // * Adds a person beneficary to the new account created.
//   // context.addPersonBeneficiary(ACCOUNT.accountId as string, { id: '21087e47-5c33-4aa1-8e5f-b555ed818dc8', name: "Bobantha Jefferson", firstName: "Bobantha", percentage: 50, email: "bobantha@gmail.com", ssn: "222-22-2222", isDeleted: true });
 
//   // * Add a transaction to the second account.
//   context.addTransaction({ transactionId: '4p66F5-MRss04KUcBnY-RuDb' }, ACCOUNT.accountId as string);
 
//   // * This function will mock all of the axios and return the correct response based on the passed in MockDigitalBankAPIs
//   // MockAxios(context);
 
//   MockAxios(context, [
//     createRejectResponse(`${bffBaseUrl}/accounts/2222/terms-and-conditions/content?isSigned=false`, 'GET', {body: {responseType: 'blob'}}) as IRejectedResponse,
//     createRejectResponse(`${bffBaseUrl}/accounts/2222/accountaggrementsign`, 'POST', {body: {isSigned: true, signedBy: "Harry Potter"}}) as IRejectedResponse,
//     createRejectResponse(`${bffBaseUrl}/yodlee/token`, 'GET') as IRejectedResponse,
//     createRejectResponse(`${bffBaseUrl}/accounts/2222/bumpupenable`, 'PATCH', {body: {bumpup: true}}) as IRejectedResponse,
//     createRejectResponse(`${bffBaseUrl}/customers/password/change-password`, 'PUT', {body: {oldPassword: "Altitude1$", newPassword: "Altitude2$"}, response: {errorMsg: ["{title=Update of credentials failed, message=Password has been used too recently}"]}}) as IRejectedResponse
//   ]);
 
//   return context;
// }
  
// createDefaultContext()

/**Use this when some accounts should fail to link and need an agent's review */
// const context = someAccountsFailedToCreateContext(2, 2)
// MockAxios(context)

// MockContext.addExternalAccount()

// MockContext.yodlee.addNonLinkedAccount();
// MockContext.yodlee.addNonLinkedAccount();
// MockContext.yodlee.addNonLinkedAccount();
// MockAxios(MockContext)

/**Use this when one or more accounts should be auto linked */
// const context = allAccountsCreatedContext(1)
// MockAxios(context)

// MockAxios(context, [
//   createRejectResponse(`${bffBaseUrl}/yodlee/verifiedAccounts`, 'POST', {body: {providerAccountId: '1'}}) as IRejectedResponse,
// ]);


/**Use this when one or more accounts should be already linked */
// const context = allAccountsAlreadyLinkedContext(2)
// MockAxios(context);

/**Use this when some accounts should fail to link and need an agent's review */
// const context = someAccountsFailedToCreateContext(2, 1)
// MockAxios(context)

/**Use this when some account should be sucessful, some should be processing, some should be pending, or any combination in between.  This is for auto linking */
// const context = someAccountRequireReviewContext(1, 1, 1)
// MockAxios(context)


// const context = selectionNeededFailureOnCreateContext(1, 0, 1)
// MockAxios(context)

/**Use this when selection is needed */
// const context = selectionNeededNoFailuresContext(3, 1)
// MockAxios(context)

/**
 * Returns if the user should be redirected to the /reroute page
 */
export const shouldReroute = () => {
  //If the isAuthenticated key set or 
  // If we are on the /authenticate page 
  // don't reroute
  if (sessionStorage.getItem('isAuthenticated') || window.location.pathname === '/authenticate') {
    return false
  }
  return isBothCustomer() || isOnlyCreditCardCustomer()
}

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getConfig()
      .then((res: any) => {
        setIsLoaded(true);
        if (shouldReroute()) {
          history.push('/reroute')
        }
      })
  }, [])

  if (!isLoaded) {
    return null;
  }

  axios.interceptors.request.use(function (config) {
    const newToken = token()
    config.headers.Platform = "web"
    if (config.url?.includes('fullverify')) {
      return config
    }
    else {
      config.headers.Authorization = 'Bearer ' + (newToken || "")
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  return (
    <div className="App">
      <WindowStateContextProvider>
        <ToastContextProvider>
          <OpenNewAccountContextProvider>
            <OTPModal />
            <NotificationProvider>
              <RequiredConsentModal />
              <TimeoutModal />
              <Routes />
            </NotificationProvider>
          </OpenNewAccountContextProvider>
        </ToastContextProvider>
      </WindowStateContextProvider>
    </div>
  );
}


export default App;
