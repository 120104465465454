import './SetupBeneficies.scss';
import beneficiaryPeople from '../../assets/beneficiaryPeople.svg';
import { ISetupBeneficiariesProps } from './interfaces/ISetupBeneficiariesProps';
import { useWindowState } from '../../Context/AccountContext/useWindowState';

/**
 * User is directed here when they have not set up any beneficiaries
 */
export const SetupBeneficies = (props: ISetupBeneficiariesProps) => {
  const { windowState } = useWindowState();
  const { setAddBeneficiaries, form, dispatchForm } = props;
  return (
    <div className="setup-beneficies">
      <div className={windowState !== 'Mobile' ? 'desktop' : 'mobile'}>
        <div className="setup-page-wrap">
          <div className="setup-wrapper">
            <div className='title-wrap'>
              <p className="empty-text h3">Beneficiaries</p>
              <p className="empty-text regular">Add or manage beneficiaries and allocation amounts.</p>
            </div>
            <div className="grey-line" />
            <img className="img" src={beneficiaryPeople} alt="people" />
            <div
              className={
                windowState !== 'Mobile'
                  ? 'title bold-x-large'
                  : 'title mobileBoldLarge'
              }
            >
              No beneficiaries
            </div>
            <div
              className={
                windowState !== 'Mobile'
                  ? 'description regular'
                  : 'description mobileRegular'
              }
            >
              You haven’t named any beneficiaries on your account yet.
            </div>
            <div className="button-wrapper">
              <button
                type="button"
                onClick={() => { setAddBeneficiaries(true); form.resetForm(); form.enableAllInputs(); dispatchForm() }}
                className={
                  windowState !== 'Mobile'
                    ? 'add-beneficiary boldLarge'
                    : 'add-beneficiary mobile mobileBoldRegular'
                }
              >
                Add beneficiary
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
