/* eslint-disable @typescript-eslint/no-unused-vars */
import { IBeneficiary } from '../../../../api/getBeneficiaries'
import BeneDeleteConfirmation from '../../../../Pages/Beneficiary/components/BeneDeleteConfirmation/BeneDeleteConfirmation'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'
import { useBeneficiaryEdit } from '../../../../useHooks/componentHooks/useBeneficiaryEdit/useBeneficiaryEdit'
import { Button, TIMER_IDS } from '../../../Button/Button'
import DisplayMobileBeneficiaryRow from './components/DisplayMobileBeneficiaryRow/DisplayMobileBeneficiaryRow'
// import MobileUnsavedChanges from './components/MobileUnsavedChanges/MobileUnsavedChanges'
import { IMobileBeneficiaryEditProps } from './interfaces/IMobileBeneficiaryEditProps'
import './MobileBeneficiaryEdit.scss'

/**
 * The component renders the Beneficiary Allocation Edit page for the mobile version.
 */
const MobileBeneficiaryEdit = (props: IMobileBeneficiaryEditProps) => {
  const { editBeneficiary, setEditBeneficiary, showAlertMessage, setShowAlertMessage, childRef, checkAllocations, setCheckAllocations,
    onAddBeneficiary, newBeneficiary, handleOnNext, handleOnReset = () => { }, onDelete = false, setOnDelete, selectedBeneficiary,
    showDeleteConfirmationPage, setShowDeleteConfirmationPage } = props;
  const { successfulAllocation, editBeneficiaryArray, onChangeHandler, totalPercentage, saveChanges, showAlertBox, selectedAccount,
    zeroValueError, sumError, resetBeneficiaryArray } = useBeneficiaryEdit(showAlertMessage as any, setShowAlertMessage, setEditBeneficiary, childRef, checkAllocations, setCheckAllocations, newBeneficiary as IBeneficiary, setOnDelete as any, setShowDeleteConfirmationPage as any, )

  const onReset = () => {
    resetBeneficiaryArray();
    document.body.style.overflow = "auto";
    handleOnReset();
    setEditBeneficiary(false)
  }

  if(showDeleteConfirmationPage){
    return <BeneDeleteConfirmation 
              selectedBeneficiary={selectedBeneficiary as IBeneficiary}
              setBeneficiaryEdit={setEditBeneficiary}
              setShowDeleteConfirmationPage={setShowDeleteConfirmationPage as any}
            />
  }

  return (
    <div className={!onAddBeneficiary ? "mobile-beneficiary-display-wrapper" : "mobile-beneficiary-display-wrapper add-flow"}>
      {!onAddBeneficiary && <span className="cancel-button regular" onClick={() => {onReset()}}>Cancel</span>}
      <div className={!onAddBeneficiary ? "card-wrap" : "card-wrap add-flow"}>
        {!onAddBeneficiary ?
          <>
            {!onDelete ?
              <span className="title mobileSmall">Beneficiaries</span>
              :
              <span className="title mobileSmall">Remove beneficiary</span>
            }
          </>
          :
          <div className="title-wrap">
            <span className="title mobileSmall">Add beneficiary</span>
            <span className="step-text mobileSmall">Step 4 of 4</span>
          </div>
        }
        <span className="title-message mobile h4">Adjust allocations</span>
        {!onDelete ?
          <>
            <p className="message">Allocations designate what percentage of your assets the beneficiary should receive.</p>
            <p className="message">Each beneficiary must have at least 1%, and the total must add up to 100%.</p>
          </>
          :
          <>
            <p className="message">Adjust the allocations for your remaining beneficiaries so they equal 100%.</p>
            <p className="message">Then, select 'Save' to finish removing your beneficiary.</p>
          </>
        }
        <div className="header-row">
          <span className="header-text">Beneficiary</span>
          <span className="header-text">Allocation</span>
        </div>
        {editBeneficiaryArray.map((beneficiary: IBeneficiary, index: number) => {
          return (
            <div key={index} className={index < selectedAccount.beneficiaries.length - 1 ? "mobile-allocation-row-wrap" : "mobile-allocation-row-wrap no-border"}>
              {!beneficiary.isDeleted &&
                <DisplayMobileBeneficiaryRow
                  index={index}
                  beneficiary={beneficiary}
                  onChangeHandler={onChangeHandler}
                />
              }
            </div>
          )
        })}
        <div className="total-wrapper">
          <div className="allocations-wrap">
            <span className="allocation-total-text mobileSemibold">Total Allocation</span>
            <div className="bottom-total">
              <span className={successfulAllocation ? "allocation-total-text mobileSemibold" : "allocation-total-text mobileBoldSmall error"}>{totalPercentage}</span>
              <div className="allocation-percentage mobileBoldSmall">%</div>
            </div>
          </div>
          <div className="errors-wrap">
            {/* {sumError && <span className="sum-error-text mobileSemibold">You have {100 - totalPercentage}% left to allocate</span>} */}
            {(zeroValueError || sumError) && (totalPercentage !== 100) && <span className="zero-error-text mobileBoldSmall">Total allocation must equal 100%</span>}
          </div>
        </div>
        <div className="button-wrap">
          <Button 
            title="Save"
            isDisabled={!successfulAllocation}
            className={successfulAllocation ? "Button btn-primary mobile" : "Button btn-primary mobile inactive"}
            id={TIMER_IDS.BENEFICIARY_ALLOCATIONS}
            onClick={() => saveChanges(handleOnNext, onAddBeneficiary ? true : false)}
          />
        </div>
      </div>
      {/* {unsavedChanges &&
        <MobileUnsavedChanges
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          setEditBeneficiary={setEditBeneficiary}
          onReset={onReset} />
      } */}
    </div>
  )
}

export default MobileBeneficiaryEdit