import { useState } from 'react'
import { useAmountFilter } from '../../../../useHooks/componentHooks/useAmountFilter/useAmountFilter'
import './AmountFilter.scss'
import { useTransactionHistoryContext } from '../../context/TransactionHistoryContext'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'

/**Renders the acmount filter, it has two inputs that are Min and Max, used on the Transaction History Page */
const AmountFilter = () => {

    const { amountFilter: { maxAmount, minAmount } } = useTransactionHistoryContext();
    const { windowState } = useWindowState()
    const {
        onChangeMax,
        onChangeMin,
        minAmountInputRef,
        maxAmountInputRef,
        checkForMaxValidation,
        getClasses
    } = useAmountFilter();



    return (
        <div className={`amount-filter-wrapper ${windowState}`}>
            <div className={`amount-input-row-wrapper ${windowState}`}>
                <div className="amount-single-input-wrap min">
                    <span className="label-text small">Minimum amount</span>
                    <div className={`${getClasses()} ${windowState}`}>
                        {minAmount.ref.get() !== null && <span className="regular">$</span>}
                        <input
                            inputMode='numeric'
                            className={`amount-input-box regular ${windowState}`}
                            type="number"
                            placeholder="Enter Amount"
                            defaultValue={minAmount.ref.get() as number}
                            ref={minAmountInputRef}
                            onChange={onChangeMin} />
                    </div>
                </div>
                <div className="amount-single-input-wrap max">
                    <span className="label-text small">Maximum amount</span>
                    <div className={`${getClasses()} ${windowState}`}>
                        {maxAmount.ref.get() !== null && <span className="regular">$</span>}
                        <input
                            inputMode='numeric'
                            className={`amount-input-box regular ${windowState}`}
                            type="number"
                            placeholder="Enter Amount"
                            defaultValue={maxAmount.ref.get() as number}
                            ref={maxAmountInputRef}
                            onChange={onChangeMax}
                            onBlur={() => checkForMaxValidation()} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AmountFilter
