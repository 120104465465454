import { useEffect, useRef, useState } from "react"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { useLinkExternalAccount } from "../../../Pages/LinkExternalAccount/Context/useLinkExternalAccount"
import { deleteExternalAccount_API, initiateMicroTransactions_API, linkExternalAccount_API } from "../../../api/User/LinkExternalAccounts"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { addExternalAccount, removeExternalAccountByIndex, setCheckInternalToken, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"
import { IExternalAccounts } from "../../../api/User/interfaces"
import { RootState } from "../../../app/store"
import { useHistory } from "react-router"
import { checkBlockList } from "../../../utils/checkBlockList"
import { IEDPErrorInterface, edpErrorReporting_API } from "../../../api/edpErrorReporting"

/**a custom use hook holding hte logic for the EnterBankDetails component found in link external account flow */
export const useEnterBankDetails = () => {
    const history = useHistory()
    const { windowState } = useWindowState()
    const { routingNumber, setRoutingNumber, setAccountNumber, setTestAccountNumber, setNickname, enableButton, setEnableButton,
        accountNumber, testAccountNumber, nickname, accountType, setStep, step, showVerified, setShowVerified, blockedRoutingError, setBlockedRoutingError, setNewExternalAccount } = useLinkExternalAccount()
    const [showTooltipOne, setShowTooltipOne] = useState<boolean>(false)
    const [showTooltipTwo, setShowTooltipTwo] = useState<boolean>(false)
    const routingNumberRef = useRef<string>("")
    const accountNumberRef = useRef<string>("")
    const testAccountNumberRef = useRef<string>("")
    const testAccountInputTouched = useRef<boolean>(false)
    const testAccountInputBlurred = useRef<boolean>(false)
    const routingInputBlurred = useRef<boolean>(false)
    const [routingError, setRoutingError] = useState<boolean>(false)
    const [accountNumberError, setAccountNumberError] = useState<boolean>(false)
    const continueClickedRef = useRef<boolean>(false)
    const bankNameRef = useRef<string>("")
    const { setToastMessage, type, message, resetToast } = useToastMessage()
    const [showDuplicateError, setShowDuplicateError] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const externalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)
    const perviousKeyRef = useRef<string>("")
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)
    const lastFourRef = useRef<string>("")
    const accountNameRef = useRef<string>("")
    const [showFraud, setShowFraud] = useState<boolean>(false)
    const apiStatusRef = useRef<true | false | null>(null)
    const [showRequirements, setShowRequirements] = useState<boolean>(false)

    /**Check for a timer token, meaning the user has not logged in directly to this page, if the user has logged in directly to this page send them to manage external accounts */
    useEffect(() => {
        const timerToken = localStorage.getItem("timerToken")
        if(!timerToken){
            history.push("/user/profile/external-accounts")
        }
    }, [])

    /**monitors that all required inputs have been successfully entered */
    useEffect(() => {
        if (routingNumber !== "" && accountNumber !== "" && accountType !== "" && testAccountNumber !== "") {
            if (!accountNumberError && !routingError && accountNumber === testAccountNumber && routingNumber.length === 9 && !blockedRoutingError) {
                setEnableButton(true)
            }
            else {
                setEnableButton(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountType, accountNumber, testAccountNumber, routingNumber])

    /**Checks the account and re-enter account inputs for an error */
    const checkAccountInputError = () => {
        if (accountNumberError && accountNumberRef.current === testAccountNumberRef.current) {
            setAccountNumberError(false)
        }
        if (testAccountInputTouched.current && accountNumberRef.current.length === testAccountNumberRef.current.length) {
            if (accountNumberRef.current !== testAccountNumberRef.current) {
                setAccountNumberError(true)
            }
        }
        if (testAccountInputBlurred.current && accountNumberRef.current.length !== testAccountNumberRef.current.length) {
            setAccountNumberError(true)
        }
    }

    /**handles the OnChange event for the routing number input */
    const handleRoutingNumber = (e: any) => {
        setRoutingNumber(e.target.value.trim())
        routingNumberRef.current = e.target.value.trim()
        if (routingError && routingNumberRef.current.length === 9) {
            setRoutingError(false)
        }
        if (routingInputBlurred.current && routingNumberRef.current.length !== 9) {
            setRoutingError(true)
        }
        setBlockedRoutingError(false)
        // if (routingNumberRef.current.length === 9 && checkBlockList(routingNumberRef.current)){
        //     setBlockedRoutingError(true)
        // }
        // else{
        //     setBlockedRoutingError(false)
        // }
    }

    const handleRoutingPaste = (e: any) => {
        routingNumberRef.current = e.clipboardData?.getData('Text') || ""
 
        const regEx = new RegExp(/^\d+$/)
        if(routingNumberRef.current !== ""){
            const result = regEx.test(routingNumberRef.current)
            if(!result){
                e.preventDefault()
            }
        }
    }

    /**handles the onChange event for the account number input */
    const handleAccountNumber = (e: any) => {
        if(showFraud){
            setShowFraud(false)
        }
        accountNumberRef.current = e.target.value
        setAccountNumber(e.target.value)
        checkAccountInputError()
    }

    /**handles the onChange event for the re-enter account number input */
    const handleTestAccountNumber = (e: any) => {
        if (!testAccountInputTouched.current) {
            testAccountInputTouched.current = true
        }
        testAccountNumberRef.current = e.target.value
        setTestAccountNumber(e.target.value)
        checkAccountInputError()
    }

    /**handles the onChange event for the optional account nickname */
    const handleAccountNickname = (e: any) => {
        setNickname(e.target.value)
    }

    /**handles the onKeyDown event for the rouuting number */
    const handleRoutingKeyDown = (e: any) => {
        if (e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Delete" && e.key !== "Backspace" && e.key !== "Tab") {
            if (perviousKeyRef.current !== "Control" && e.key !== "v") {
                let patternNumber = new RegExp("^(?=.*\\d).+$")
                if (!patternNumber.test(e.key)) {
                    e.preventDefault()
                }  
            }
        }
        if ((perviousKeyRef.current !== "Control" && e.key === "v") && (perviousKeyRef.current !== 'Meta' && e.key === 'v')){
            e.preventDefault()
        }
        perviousKeyRef.current = e.key
    }

    /**handles the onKeyDown event for the account number and re-enter acccount number inputs */
    const handleAccountKeyDown = (e: any) => {
        if (e.key === "." || e.key === "+" || e.key === "-" || e.key === "e") {
            e.preventDefault()
        }
    }

    /**handles the routing number input on blur event */
    const handleRoutingOnBlur = () => {
        routingInputBlurred.current = true
        if (routingNumber.length !== 9) {
            setRoutingError(true)
        }
        // if(checkBlockList(routingNumber)){
        //     setBlockedRoutingError(true)
        // }
        // else{
        //     setBlockedRoutingError(false)
        // }
    }

    /**handles the account number input on blur event */
    const handleAccountOnBlur = () => {
        if (testAccountNumber.length > 0 && accountNumber !== testAccountNumber) {
            setAccountNumberError(true)
        }
        else {
            setAccountNumberError(false)
        }
    }

    /**handles the re-enter account number input on blur event */
    const handleTestAccountOnBlur = () => {
        if (testAccountNumber.length > 0 && accountNumber !== testAccountNumber) {
            setAccountNumberError(true)
        }
        else {
            setAccountNumberError(false)
        }
        testAccountInputBlurred.current = true
    }

    /**use effect hook monitors the state of the OTP modal status*/
    useEffect(() => {
        if (OTPPassed && continueClickedRef.current) {
            // handleCallService()
            handleServiceCalls()
            // dispatch(setOTPPassed(false))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    // const handleCallService = () => {
    //     return handleServiceCalls()
    // }

    /**Handles the service calls for creating a new external account */
    const handleServiceCalls = async () => {
        apiStatusRef.current = true
        const account = {
            accountType: accountType === "Savings" ? "SAV" : "DDA",
            routingNumber: routingNumber,
            accountNumber: accountNumber,
            channel: "Web",
            accountTitle: nickname !== "" ? nickname : bankNameRef.current
        }
        try {
            const response = await linkExternalAccount_API(account)
            const tempAccount: IExternalAccounts = {
                id: response.data.externalAccountId,
                accountNickName: response.data.accountTitle || "",
                status: "Linked",
                lastFour: response.data.accountNumber,
                routingNumber: response.data.routingNumber,
                type: response.data.accountType,
                description: response.data.bankAccountName || ""
            }

            if (response.data.status.toLowerCase() === "verified" || response.data.status.toLowerCase() === "fraud") {
                if (response.data.status.toLowerCase() === "verified") {
                    dispatch(addExternalAccount(tempAccount))
                    lastFourRef.current = response.data.accountNumber
                    accountNameRef.current = response.data.accountTitle
                    setShowVerified(true)
                    // Promise.resolve(true)
                }
                else {
                    setShowFraud(true)
                    setEnableButton(false)
                    continueClickedRef.current = false
                }
            }
            else {
                tempAccount.status = "Verify now"
                setNewExternalAccount(tempAccount)
                setStep(2)
                continueClickedRef.current = false
            }
        }
        catch (error: any) {
            let errorReturn: any = error.response ? JSON.parse(JSON.stringify(error.response)) : ""

            if (errorReturn !== "" && errorReturn.data.errorMsg && errorReturn.data.errorMsg[0].startsWith('Account number is already present for this customerId')) {
                setShowDuplicateError(true)
                continueClickedRef.current = false
                setEnableButton(false)
            }
            else if (errorReturn !== "" && errorReturn.data.errorMsg && (errorReturn.data.errorMsg[0].startsWith('Account locked due to Fraud suspicions. Please contact customer support'))){
                setShowFraud(true)
                setEnableButton(false)
                continueClickedRef.current = false
            }
            else if (errorReturn !== "" && errorReturn.data.errorMsg && errorReturn.data.errorMsg[0].startsWith('Fraud account from EWS for this accountId')){
                setShowFraud(true)
                setEnableButton(false)
                continueClickedRef.current = false
            }
            else {
                setToastMessage("ERROR", "Unable to save changes. Please try again later")
                resetToast()
                continueClickedRef.current = false
                setEnableButton(false)
            }

            // let edpErrorObject: IEDPErrorInterface = {
            //     category: "External_Account",
            //     errorType: "API_error",
            //     errorMessage: [{message: errorReturn?.data?.errorMsg[0] || ""}, {message: "ENTER BANK DETAILS"}],
            //     timeStamp: new Date().toISOString(),
            //     additionalDetails: {
            //         externalAccounts: [{
            //             acctNo: accountNumber.slice(accountNumber.length - 4, accountNumber.length),
            //             acctId: "",
            //             routingNo: routingNumber,
            //             bankName: nickname !== "" ? nickname : bankNameRef.current,
            //             errorCode: errorReturn.status || "",
            //             verificationMethod: ""
            //         }]
            //     }
            // }
            // try{
            //     edpErrorReporting_API(edpErrorObject)
            // }
            // catch{}


        }
        continueClickedRef.current = false
        apiStatusRef.current = false
        dispatch(setOTPPassed(false))
    }

    /**hanldes the continue button onClick event */
    const handleContinue = () => {
        if (routingNumberRef.current.length === 9 && checkBlockList(routingNumberRef.current)) {
            setBlockedRoutingError(true)
            setEnableButton(false)
            return
        }
        // else{
        //     setBlockedRoutingError(false)
        // }

        if (!continueClickedRef.current) {
            continueClickedRef.current = true
            dispatch(setOTPPassed(false))
            setShowDuplicateError(false)
            dispatch(setCheckInternalToken(true))
            return new Promise((resolve, reject) => {
                let interval: any;
                interval = setInterval(() => {
                    if (apiStatusRef.current === false) {
                        apiStatusRef.current = null
                        resolve(true)
                        clearInterval(interval)
                    }

                }, 100)
            })
            // continueClickedRef.current = false
        }
    }

    return {
        windowState, showTooltipOne, setShowTooltipOne, showTooltipTwo, setShowTooltipTwo, handleRoutingNumber, handleAccountNumber,
        handleTestAccountNumber, handleAccountNickname, handleRoutingKeyDown, handleRoutingOnBlur, routingError, handleAccountOnBlur,
        accountNumberError, handleAccountKeyDown, handleTestAccountOnBlur, enableButton, handleContinue, showDuplicateError, type, message,
        lastFourRef, accountNameRef, showFraud, handleRoutingPaste, showRequirements, setShowRequirements
    }
}