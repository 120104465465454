import { ITransferHistory } from "../api/transfers"

/**Populates the transfer history array */
export const populateTransfers = (transferArray: any, status: "Scheduled" | "") => {
    const tempArray: Array<ITransferHistory> = []
    for(let i = 0; i < transferArray.length; i = i + 1){
        let temp: ITransferHistory = {
            id: transferArray[i].transferDetails.transferId,
            transferDate: transferArray[i].transferDetails.transferDate,
            transferFromAccount: transferArray[i].fromAccount.name || "",
            originLastFour: transferArray[i].fromAccount.lastFourDigits,
            transferDesignationAccount: transferArray[i].toAccount.name || "",
            designationLastFour: transferArray[i].toAccount.lastFourDigits,
            amount: transferArray[i].transferDetails.transferAmount,
            status: status,
            fromAccountType: transferArray[i].fromAccount.accountType.toLowerCase() !== "external" ? transferArray[i].fromAccount.accountType || "" : "",
            designationAccountType: transferArray[i].toAccount.accountType.toLowerCase() !== "external" ? transferArray[i].toAccount.accountType || "": "",
            orderReference: transferArray[i].transferDetails.orderReference || "",
            isInitialFund: transferArray[i].transferDetails.isInitialFund || false,
            isCancelAllowed: transferArray[i].transferDetails.isCancelAllowed
        }
        tempArray.push(temp)
    }

    return tempArray
}