import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ITransactionHistoryData } from '../../../api/getTransactionHistory';
import { postMemo_API } from '../../../api/postMemo';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import {  updateSelectedTransactionMemo } from '../../../slices/selectedTransaction/selectedTransactionSlice';
import {  updateTransactionMemoByIndex } from '../../../slices/transactions/transactionsSlice';
import { useToastMessage } from '../../../Context/ToastContext/useToastContext';
import { useTransactionHistoryContext } from '../../../Pages/TransactionHistory/context/TransactionHistoryContext';

/**
 * UseHook for memo
 * @param selectedTransaction transaction that was selected
 */
export const useMemoComponent = (selectedTransaction: ITransactionHistoryData, displayDetails: boolean | undefined) => {
  const [editMemo, setEditMemo] = useState(false);
  const memoInputRef = useRef<any>();
  const [characterCount, setCharacterCount] = useState(
    selectedTransaction.memo.length
  );
  const transactionHistory = useAppSelector((state: RootState) => state.transactions.transactions)
  const dispatch = useDispatch()
  const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
  const {type, message, resetToast, setToastMessage} = useToastMessage()
  const isMemoClickedRef = useRef<boolean>(false)
  const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
  const { setFilteredTransactions } = useTransactionHistoryContext()
  const { filteredTransactions } = useTransactionHistoryContext()

  /**
   * handles action to cancel editing the memo
   */
  const handleCancel = () => {
    setEditMemo(false);
    setCharacterCount(selectedTransaction.memo.length);
  };

  /**
   * handles action to save a memo to a transaction
   */
  const handleSave = async () => {
    if(!isMemoClickedRef.current){
      isMemoClickedRef.current = true
      const index = transactionHistory.findIndex((transaction: ITransactionHistoryData) => transaction.id === selectedTransaction.id)
      try{
        await postMemo_API(selectedAccount.id, selectedTransaction.id, memoInputRef.current.value, selectedTransaction.index, accountInformation)
        const payload = {arrayIndex: index, memo: memoInputRef.current.value}
        dispatch(updateSelectedTransactionMemo(memoInputRef.current.value))
        dispatch(updateTransactionMemoByIndex(payload))
        setEditMemo(false);
        setToastMessage("SUCCESS", "Memo saved.")
        resetToast()
        const tempTransactions = JSON.parse(JSON.stringify(filteredTransactions))
        const filterIndex = filteredTransactions.findIndex((transaction: ITransactionHistoryData) => transaction.id === selectedTransaction.id)
        tempTransactions[filterIndex].memo =  payload.memo
        setFilteredTransactions(tempTransactions)
        isMemoClickedRef.current = false
      }
      catch {
        //throw new Error("Encountered an error updating the transaction memo.")
        setToastMessage("ERROR", "Unable to save changes. Please try again later.")
        resetToast();
        isMemoClickedRef.current = false
      }
    }
  };

  /**
   * sets the editMemo state to false if the transactions details panel is closed.
   */
  useEffect(() => {
    if (displayDetails === false) {
      setEditMemo(false);
    }
  }, [displayDetails]);

  return {
    editMemo,
    setEditMemo,
    memoInputRef,
    characterCount,
    setCharacterCount,
    handleCancel,
    handleSave,
    selectedTransaction,
    type,
    message
  };
};
