import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import './BeneDeleteConfirmation.scss'
import greenCheckmark from '../../../../assets/greenCheckmark.svg'
import { IBeneDeleteConfirmationProps } from './interfaces/IBeneDeleteConfirmationProps'

const BeneDeleteConfirmation = (props: IBeneDeleteConfirmationProps) => {
    const { windowState } = useWindowState()
    const { selectedBeneficiary, setBeneficiaryEdit, setShowDeleteConfirmationPage } = props

    const handleButtonClick = () => {
        setBeneficiaryEdit(false)
        setShowDeleteConfirmationPage(false)
    }

    return (
        <div className={`bene-delete-page-wrapper ${windowState}`}>
            <div className={`bene-delete-confirmation-wrapper ${windowState}`}>
                <img className={`checkmark ${windowState}`} src={greenCheckmark} alt="green checkmark" />
                <p className={windowState !== "Mobile" ? "bold-x-large" : "mobileh3"}>Beneficiary removed</p>
                <p className={`bene-text regular ${windowState}`}>{selectedBeneficiary.name} has been removed from your account.</p>
                <button className={`Button btn-primary ${windowState}`} onClick={() => handleButtonClick()}>Return to beneficiaries</button>
            </div>
        </div>
    )
}

export default BeneDeleteConfirmation