/* eslint-disable @typescript-eslint/no-unused-vars */
import './DesktopNotificationSettings.scss'
import { IDesktopNotificationSettingsProps } from './interface/IDesktopNotificationSettingsProps'
import { useNotificationSettings } from '../../../useHooks/componentHooks/useNotificationSettings/useNotificationSettings'
import DesktopProfileHeader from '../../../Pages/UserProfile/components/DesktopProfileHeader/DesktopProfileHeader'
import Toggle from '../../Toggle/Toggle'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import warning from '../../../assets/warning.svg'
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage'

/**
 * Renders the desktop version of the Notification Settings tab found on the User Profile page
 */
const DesktopNotificationSettings = (props: IDesktopNotificationSettingsProps) => {
    const { currentTab, setCurrentTab, availableTabs } = props
    const { handleToggleClickOne, toggleOne, type, message } = useNotificationSettings()
    const gotSMS = useAppSelector((state: RootState) => state.userInformation.gotSMS)

    return(
        <div className="desktop-notification-settings">
            {message !== "" && <ToastMessageDB type={type} message={message} />}
            <DesktopProfileHeader 
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                availableTabs={availableTabs}
            />
            <div className="page-card-wrap">
                {gotSMS !== "Loading" ?
                    <div className="top-card">
                        {gotSMS === "Success" ?
                            <>
                            <span className="header-text h2">Notifications settings</span>
                            <div className="notification-data-wrap">
                                <div className="top-section">
                                    <div className="left-side">
                                        <span className="sign-in-header boldRegular">Text notifications</span>
                                        <span className="sign-in-text regular">Receive account notifications by text message on your mobile phone. Standard service provider rates apply.</span>
                                    </div>
                                    <Toggle 
                                        toggle={toggleOne}
                                        setToggle={handleToggleClickOne}
                                        fromNotifications={true}
                                    />
                                </div>
                                {toggleOne ?
                                    <span className="info-text small">You authorize Credit One Bank to send SMS text notifications regarding your Credit One Bank accounts using the mobile phone number provided. The frequency of messages may vary based upon your account(s), account activity, servicing issues and the like. You may revoke authorization for most communications; however, you cannot revoke authorization for fraud related or legally required communications. If you do not wish to receive all of these text notifications, certain communication preferences can be changed on-line after logging into your Account or you can text STOP to opt-out of all notifications except fraud and legally required communications. You can also text HELP for more information and assistance or log into your online Account. Message and data rates may apply.</span>
                                    :
                                    <span className="info-text small">You authorize Credit One Bank to send SMS text notifications regarding your Credit One Bank accounts using the mobile phone number provided. The frequency of messages may vary based upon your account(s), account activity, servicing issues and the like. You may revoke authorization for most communications; however, you cannot revoke authorization for fraud related or legally required communications. If you do not wish to receive all of these text notifications, certain communication preferences can be changed on-line after logging into your Account or you can text STOP to opt-out of all notifications except fraud and legally required communications. You can also text HELP for more information and assistance or log into your online Account. Message and data rates may apply.</span>
                                }
                            </div>
                            </>
                            :
                            <div className="failed-wrapper">
                                <img src={warning} alt="unable to retreive data" />
                                <span className="header-text h4">Unable to load data</span>
                                <span className="sub-text regular">Please wait a moment and try again.</span>
                            </div>
                        }
                    </div>
                    :
                    <div className="loading-box"/>
                }
            </div>
        </div>
    )
}

export default DesktopNotificationSettings
