import { IAddBeneficiaryFormProps } from './interfaces/IAddBeneficiaryFormProps';
import './AddBeneficiaryForm.scss';
import { SelectBeneficiaryType } from './components/SelectBeneficiaryType/SelectBeneficiaryType';
import { BeneficiaryPersonalInformation } from './components/AddPersonal/BeneficiaryPersonalInformation/BeneficiaryPersonalInformation';
import { AddCharityRequiredInformation } from './components/AddCharity/AddCharityRequiredInformation/AddCharityRequiredInformation';
import { AddTrustRequiredInformation } from './components/AddTrust/AddTrustRequiredInformation/AddTrustRequiredInformation';
import { useAddBeneficiary } from '../../useHooks/componentHooks/useAddBeneficiary/useAddBeneficiary';
import { useWindowState } from '../../Context/AccountContext/useWindowState';
import BeneficiaryReview from '../BeneficiaryReview/BeneficiaryReview';
import BeneficiaryConfirmation from '../BeneficiaryConfirmation/BeneficiaryConfirmation';
import DesktopBeneficiaryEdit from '../BeneficiaryEdit/components/DesktopBeneficiaryEdit/DesktopBeneficiaryEdit';
import MobileBeneficiaryEdit from '../BeneficiaryEdit/components/MobileBeneficiaryEdit/MobileBeneficiaryEdit';
import arrowLeftBlue from '../../assets/arrowLeftBlue.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { setUpdatedBeneficiaryArray } from '../../slices/selectedAccountSlice/selectedAccountSlice';
import { useHistory } from 'react-router-dom';

/**
 * Parent component for adding a beneficiary
 */
export const AddBeneficiaryForm = (props: IAddBeneficiaryFormProps) => {
  const { windowState } = useWindowState();
  const {
    setDisplayAddBeneficiary,
    setEditBeneficiary,
    setShowAlertMessage,
    currentPage,
    setCurrentPage,
    newBeneficiary,
    setNewBeneficiary,
    form,
    dispatchForm,
    childRef,
    checkAllocations,
    setCheckAllocations,
  } = props;
  const {
    selectedAccount,
    pages,
    handleOnBack,
    handleOnCancel,
    handleOnNext,
    handleOnFinish,
  } = useAddBeneficiary(
    setDisplayAddBeneficiary,
    setEditBeneficiary,
    setShowAlertMessage,
    currentPage,
    setCurrentPage,
    newBeneficiary,
    setNewBeneficiary,
    form,
    dispatchForm
  );

  const dispatch = useAppDispatch()
  const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
  const history = useHistory()

  const reset = () => {
    let accountIndex = accountInformation.findIndex((account) => account.accountNumber === selectedAccount.accountNumber);

    document.body.style.overflow = "auto";
    dispatch(setUpdatedBeneficiaryArray(accountInformation[accountIndex].beneficiaries));
    form.resetForm()
    dispatchForm()
    setEditBeneficiary(false)
    setDisplayAddBeneficiary(false)
    setCurrentPage(0)
    // handleOnReset();
  }


  return (
    <div className="card-wrapper add-beneficiary-form">
      {windowState === "Mobile" &&
        <>
          {pages[currentPage] !== "Confirmation" ?
            <div className="button-options-wrap">
              {pages[currentPage] !== "SelectType" && (pages[currentPage] !== "Allocations" && selectedAccount.beneficiaries.length > 0) ?
                <div className="left-side" onClick={() => handleOnBack()}>
                  <img src={arrowLeftBlue} alt="back button" />
                  <span className="back-text regular">Back</span>
                </div>
                :
                <span>{" "}</span>
              }
              <span className="cancel-button regular" onClick={() => {
                if (pages[currentPage] === "Allocations"){
                  reset()
                }
                else {
                  form.resetForm(); dispatchForm(); setDisplayAddBeneficiary(false); setCurrentPage(0)
                }
              }} >Cancel</span>
            </div>
            :
            <span className="cancel-button regular" >{" "}</span>
          }
        </>
      }
      <div className="container">
        <div className={windowState !== 'Mobile' ? 'desktop' : 'mobile form'}>
          {pages[currentPage] !== "Confirmation" && <div className="progress-indicator"><div className="progress-bar" style={{ width: `calc(100% / ${selectedAccount.beneficiaries.length > 0 ? 4 : 3} * ${currentPage + 1}` }}></div></div>}
          <div className="content-wrapper">
            {pages[currentPage] === 'SelectType' && (
              <div className="form-wrapper">
                <SelectBeneficiaryType
                  setNewBeneficiary={setNewBeneficiary}
                  newBeneficiary={newBeneficiary}
                  handleOnNext={handleOnNext}
                  handleOnCancel={handleOnCancel}
                  selectedAccount={selectedAccount}
                />
              </div>
            )}

            {pages[currentPage] === 'RequiredInformation' && (
              <div className="form-wrapper">
                {newBeneficiary.type === 'Person' && (
                  <BeneficiaryPersonalInformation
                    newBeneficiary={newBeneficiary}
                    setNewBeneficiary={setNewBeneficiary}
                    handleOnCancel={handleOnCancel}
                    handleOnNext={handleOnNext}
                    handleOnFinish={handleOnFinish}
                    setCurrentPage={setCurrentPage}
                    form={form}
                    selectedAccount={selectedAccount}
                    dispatchForm={dispatchForm}
                  />
                )}
                {newBeneficiary.type === 'Charity' && (
                  <AddCharityRequiredInformation
                    newBeneficiary={newBeneficiary}
                    setNewBeneficiary={setNewBeneficiary}
                    handleOnCancel={handleOnCancel}
                    handleOnNext={handleOnNext}
                    handleOnFinish={handleOnFinish}
                    setCurrentPage={setCurrentPage}
                    form={form}
                    selectedAccount={selectedAccount}
                    dispatchForm={dispatchForm}
                  />
                )}
                {newBeneficiary.type === 'Trust' && (
                  <AddTrustRequiredInformation
                    newBeneficiary={newBeneficiary}
                    setNewBeneficiary={setNewBeneficiary}
                    handleOnCancel={handleOnCancel}
                    handleOnNext={handleOnNext}
                    handleOnFinish={handleOnFinish}
                    setCurrentPage={setCurrentPage}
                    form={form}
                    selectedAccount={selectedAccount}
                    dispatchForm={dispatchForm}
                  />
                )}
              </div>
            )}
            {pages[currentPage] === "ReviewInformation" && (
              <BeneficiaryReview
                newBeneficiary={newBeneficiary}
                handleOnCancel={handleOnCancel}
                handleOnNext={handleOnNext}
                handleOnFinish={handleOnFinish}
                form={form}
                handleOnBack={handleOnBack}
                selectedAccount={selectedAccount}
              />
            )}
            {(pages[currentPage] === "Allocations" && selectedAccount.beneficiaries.length > 0) &&
              <>
                {windowState !== "Mobile" ?
                  <DesktopBeneficiaryEdit
                    setEditBeneficiary={setEditBeneficiary}
                    checkAllocations={checkAllocations}
                    childRef={childRef}
                    setCheckAllocations={setCheckAllocations}
                    handleOnNext={handleOnNext}
                    onAddBeneficiary={true}
                    newBeneficiary={newBeneficiary}
                    handleOnReset={handleOnCancel}
                  />
                  :
                  <MobileBeneficiaryEdit
                    setEditBeneficiary={setEditBeneficiary}
                    childRef={childRef}
                    checkAllocations={checkAllocations}
                    setCheckAllocations={setCheckAllocations}
                    onAddBeneficiary={true}
                    handleOnNext={handleOnNext}
                    newBeneficiary={newBeneficiary}
                    handleOnReset={handleOnCancel}
                  />
                }
              </>
            }
            {pages[currentPage] === "Confirmation" && (
              <BeneficiaryConfirmation
                setCurrentPage={setCurrentPage}
                setDisplayAddBeneficiary={setDisplayAddBeneficiary}
                setNewBeneficiary={setNewBeneficiary}
                form={form}
                dispatchForm={dispatchForm}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
