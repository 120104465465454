import { useEffect } from 'react'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import './LinkRequirementTooltip.scss'
import { getEventPath } from '../../../../utils/getEventPath'
import topAnchor from '../../../../assets/topAnchor.svg'

/**Displays the tooltip information for both desktop and mobile showing the requirements for linking an external account */
const LinkRequirementTooltip = (props: {setShowRequirements: React.Dispatch<React.SetStateAction<boolean>>}) => {
    const { windowState } = useWindowState()
    const { setShowRequirements } = props

    /**useEffect controls locking and unlocking the scrollbar */
    useEffect(() => {
        if (windowState === "Mobile") {
            document.body.style.overflow = "hidden";
        }
        return () => { document.body.style.overflow = "auto" };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**Checks for click outside of menu box, if ouside then the menu box is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if (Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("message-wrapper")) === -1) {
                if (windowState === "Mobile" && setShowRequirements) {
                    setShowRequirements(false)
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {windowState !== "Mobile" ?
                <div className="desktop-link-requirements-wrap">
                    <img className="shadow" src={topAnchor} alt="anchor point" />
                    <div className="link-requirement-message-wrap">
                        <span className="header-message boldSmall">External account requirements:</span>
                        <div className="requirement-row">
                            <span className="message-text small">&nbsp;&nbsp;• </span>
                            <span className="message-text small">Must be individually or jointly owned by you.</span>
                        </div>
                        <div className="requirement-row">
                            <span className="message-text small">&nbsp;&nbsp;• </span>
                            <span className="message-text small">Must be held at a U.S. financial institution.</span>
                        </div>
                        <div className="requirement-row">
                            <span className="message-text small">&nbsp;&nbsp;• </span>
                            <span className="message-text small">May not be a business or trust account, or an account for a fiduciary (e.g., executors, conservators).</span>
                        </div>
                    </div>
                </div>
                :
                <div data-testid="overlay" className="mobile-link-requirements-wrap">
                    <div className="message-wrapper">
                        <div className="header-wrap">
                            <span className="header-message semiboldRegular">External Account Requirements</span>
                            {setShowRequirements &&
                                <span className="blue-text regular" onClick={() => setShowRequirements(false)}>Close</span>
                            }
                        </div>
                        <div className="body-wrap">
                            <div className="requirement-row">
                                <span className="body-text regular">• </span>
                                <span className="body-text regular">Must be individually or jointly owned by you.</span>
                            </div>
                            <div className="requirement-row">
                                <span className="body-text regular">• </span>
                                <span className="body-text regular">Must be held at a U.S. financial institution.</span>
                            </div>
                            <div className="requirement-row">
                                <span className="body-text regular">• </span>
                                <span className="body-text regular">May not be a business or trust account, or an account for a fiduciary (e.g., executors, conservators).</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LinkRequirementTooltip