/* eslint-disable @typescript-eslint/no-unused-vars */
import { MobileLayout } from '../../../layouts/MobileLayout/MobileLayout';
import './MobileBeneficiary.scss';
import MobileBeneficiaryEdit from '../../../components/BeneficiaryEdit/components/MobileBeneficiaryEdit/MobileBeneficiaryEdit';
import MobileBeneficiarySummary from '../../../components/BeneficiarySummary/components/MobileSummary/MobileBeneficiarySummary';
import { AddBeneficiaryForm } from '../../../components/BeneficiaryAdd/AddBeneficiaryForm';
import MobileEditBeneficiaryProfile from '../../../components/BeneficiaryEditProfile/components/MobileEditBeneficiaryProfile/MobileEditBeneficiaryProfile';
import MobileBeneficiaryDelete from '../../../components/BeneficiaryDelete/MobileBeneficiaryDelete/MobileBeneficiaryDelete';
import { IMobileBeneficiaryProps } from './interfaces/IMobileBeneficiaryProps';

/**
 * Mobile Beneficiary landing page.  Displays the Account Header, and beneficiary details.
 */
const MobileBeneficiary = (props: IMobileBeneficiaryProps) => {
    const { editBeneficiary, setEditBeneficiary, editProfile, setEditProfile, showAlertMessage, setShowAlertMessage, addBeneficiary, setAddBeneficiary,
        setDeleteBeneficiary, selectedBeneficiary, selectedAccount, setSelectedBeneficiary, deleteBeneficiary, childRef, checkAllocations, setCheckAllocations, 
        currentPage, setCurrentPage, newBeneficiary, setNewBeneficiary, form, dispatchForm, handleOnClick, onDelete, setOnDelete,
        showDeleteConfirmationPage, setShowDeleteConfirmationPage } = props

    return (
        <MobileLayout>
            <div className="Mobile-Beneficiary-Wrapper" ref={childRef}>
                <div>
                    {(!editBeneficiary && !editProfile && !addBeneficiary) &&
                        <MobileBeneficiarySummary
                            setEditBeneficiary={setEditBeneficiary}
                            showAlertMessage={showAlertMessage}
                            setShowAlertMessage={setShowAlertMessage}
                            setEditProfile={setEditProfile}
                            setAddBeneficiary={setAddBeneficiary}
                            setSelectedBeneficiary={setSelectedBeneficiary}
                            form={form}
                            dispatchForm={dispatchForm}
                            handleOnClick={handleOnClick}
                            setDeleteBeneficiary={setDeleteBeneficiary}
                        />
                    }
                    {editBeneficiary &&
                        <MobileBeneficiaryEdit
                            editBeneficiary={editBeneficiary}
                            setEditBeneficiary={setEditBeneficiary}
                            showAlertMessage={showAlertMessage}
                            setShowAlertMessage={setShowAlertMessage}
                            childRef={childRef}
                            checkAllocations={checkAllocations}
                            setCheckAllocations={setCheckAllocations}
                            onAddBeneficiary={false}
                            onDelete={onDelete}
                            setOnDelete={setOnDelete}
                            selectedBeneficiary={selectedBeneficiary}
                            showDeleteConfirmationPage={showDeleteConfirmationPage}
                            setShowDeleteConfirmationPage={setShowDeleteConfirmationPage}
                        />
                    }
                    {editProfile &&
                        <MobileEditBeneficiaryProfile
                            setEditProfile={setEditProfile}
                            setEditBeneficiary={setEditBeneficiary}
                            selectedBeneficiary={selectedBeneficiary}
                            setDeleteBeneficiary={setDeleteBeneficiary}
                            form={form}
                            dispatchForm={dispatchForm}
                        />
                    }
                    {deleteBeneficiary && (
                        <MobileBeneficiaryDelete
                            setDeleteBeneficiary={setDeleteBeneficiary}
                            selectedBeneficiary={selectedBeneficiary}
                            setEditBeneficiary={setEditBeneficiary}
                            setEditProfile={setEditProfile}
                            setShowAlertMessage={setShowAlertMessage}
                            setCheckAllocations={setCheckAllocations}
                            setOnDelete={setOnDelete}
                        />
                    )}
                    {addBeneficiary && (
                        <div className="add-beneficiary-wrapper">
                            <AddBeneficiaryForm
                                setDisplayAddBeneficiary={setAddBeneficiary}
                                setEditBeneficiary={setEditBeneficiary}
                                setShowAlertMessage={setShowAlertMessage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                newBeneficiary={newBeneficiary}
                                setNewBeneficiary={setNewBeneficiary}
                                form={form}
                                dispatchForm={dispatchForm}
                                childRef={childRef}
                                checkAllocations={checkAllocations}
                                setCheckAllocations={setCheckAllocations}
                            />
                        </div>
                    )}
                </div>
            </div>
        </MobileLayout>
    )
}

export default MobileBeneficiary;
