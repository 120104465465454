/* eslint-disable @typescript-eslint/no-unused-vars */
import { Action, ActionReducerMapBuilder, CaseReducer, createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { createAccountInformationObject, getAccountDetails_API, getAccountInformation_API, getProductList_API, getTaxDocuments_API, IAccountAgreements, IAccountNotices, IEarlyWithdrawal, IFundTransfer, IGracePeriodFundTransfer, IOwner, IProduct, ISelectedInterestAccount, ITaxDocuments, mockbasicAccountInfo } from '../../api/getAccountInformation';
import { IBeneficiary } from '../../api/getBeneficiaries';
import { IStatements } from '../../api/getStatements';
import { patchAccountNickName_API } from '../../api/patchAccountNickName';
import { IInterestSettings } from '../../api/putInterestSettings';
import { IMaturitySetting } from '../../api/putMaturitySettings';
import { RootState, AppThunk } from '../../app/store';
import { convertDateTommddyyyy } from '../../utils/DateUtils';
import { ITransactionHistoryData } from '../transactions/transactionsSlice';
import { populateAccountNotices, populateStatements, populateTaxDocuments } from '../../utils/populateStatements';
import { IGraceTermRenewal } from '../../api/getAccountInformation/types/getAccountInformation.types';
import { buildTermsAndConditions } from '../../utils/buildTermsAndConditions';
import { AccountListResponse, InlineResponse400 } from '../../api/models';

export interface IAccountInformation {
  accountInformation: [{
    id: string
    accountType: string
    accountNumber: string
    accountTitle: string
    description: string
    balance: number
    interestRate: number
    term: string
    maturityDate: string
    routingNumber: string
    owner: Array<IOwner>
    interestPaid: number
    interestPaidYTD: number;
    interestPaidPriorYear: number;
    beneficiaries: Array<IBeneficiary>;
    maturitySetting: IMaturitySetting;
    selectedInterestAccount: ISelectedInterestAccount;
    interestAccount: IInterestSettings;
    accountStatements: Array<IStatements>;
    taxDocuments: Array<ITaxDocuments>;
    accountAgreements: Array<IAccountAgreements>;
    accountNotices: Array<IAccountNotices>;
    statements: Array<IStatements>;
    isBeneficiariesLoaded: boolean;
    isTransactionsLoaded: boolean
    gotBeneficiaryDetails: boolean
    creationDate: any
    graceEndDate: any
    graceStartDate: any
    bumpActivated: boolean
    bannerDismissed: boolean
    bumpedRateDate: any
    bumpedRate: number
    fundTransfer?: IFundTransfer
    earlyWithdrawal: IEarlyWithdrawal
    earlyWithdrawalSelected: boolean
    accountClosed: boolean
    accountClosedDate: any
    isGracePeriod: boolean
    gracePeriodFundTransfer: IGracePeriodFundTransfer
    transactions: Array<ITransactionHistoryData>
    isDeleted: boolean
    isBannerAvailable: boolean,
    originalApy: number
    gotAccountDetails: boolean
    totalCDBalance: number,
    totalSavingsBalance: number,
    totalBalance: number,
    cdGroup: "",
    isBumpAvailable: boolean,
    gotAccountStatements: boolean
    gotAccountNotices: boolean
    gotInterestSettings: boolean,
    graceTermRenewal: IGraceTermRenewal
    currentBalance: number
    gotAccountAgreements: boolean,
    earlyWithdrawalPenalty: string,
    loyaltyRate: boolean,
    pendingGraceClosure: boolean
  }],
  isAccountInformationLoaded: "Loading" | "Success" | "Failed",
  totalCDBalance: number;
  totalSavingsBalance: number;
  totalBalance: number | string;
  CDProductList: Array<IProduct>
  BumpProductList: Array<IProduct>
  SavingsProductList: Array<IProduct>
  isConsentRequired: boolean
  consentRequiredAccounts: Array<any>
  gotProductList: "Loading" | "Success" | "Failed"
  gotTaxDocuments: "Loading" | "Success" | "Failed"
  gotNotices: "Loading" | "Success" | "Failed",
  gotAgreements: "Loading" | "Success" | "Failed"
}

const initialState: IAccountInformation = {
  accountInformation: [{
    id: "",
    accountType: "",
    accountNumber: "",
    accountTitle: "",
    description: "",
    balance: 0,
    interestRate: 0,
    term: "",
    maturityDate: "",
    routingNumber: "",
    owner: [],
    interestPaid: 0,
    interestPaidYTD: 0,
    interestPaidPriorYear: 0,
    beneficiaries: [],
    maturitySetting: { option: "", termLength: "" },
    selectedInterestAccount: { accountTitle: "", accountNumber: "", accountType: "", id: "" },
    interestAccount: { accountNumber: "" },
    accountStatements: [],
    taxDocuments: [],
    accountAgreements: [],
    accountNotices: [],
    statements: [],
    isBeneficiariesLoaded: false,
    isTransactionsLoaded: false,
    gotBeneficiaryDetails: false,
    creationDate: "",
    graceEndDate: "",
    graceStartDate: "",
    bumpActivated: false,
    bannerDismissed: false,
    bumpedRateDate: "",
    bumpedRate: 0,
    fundTransfer: { id: "", amount: 0, accountDescription: "", accountNumber: "" },
    earlyWithdrawal: {
      transferAccount: "",
      transferAmount: 0,
      transferDate: "",
      transferAccountTitle: "",
      optionalAnswer: ""
    },
    earlyWithdrawalSelected: false,
    accountClosed: false,
    accountClosedDate: "",
    isGracePeriod: false,
    gracePeriodFundTransfer: { transferAccount: "", transferAmount: 0, transferType: "", transferAccountId: "", transferId: "" },
    transactions: [],
    isDeleted: false,
    gotAccountDetails: false,
    isBannerAvailable: false,
    originalApy: 0,
    totalCDBalance: 0,
    totalSavingsBalance: 0,
    totalBalance: 0,
    cdGroup: "",
    isBumpAvailable: false,
    gotAccountStatements: false,
    gotAccountNotices: false,
    gotInterestSettings: false,
    graceTermRenewal: { cdGroup: "", cdTerm: "", apy: "", productCode: "" },
    currentBalance: 0,
    gotAccountAgreements: false,
    earlyWithdrawalPenalty: "",
    loyaltyRate: false,
    pendingGraceClosure: false
  }],
  isAccountInformationLoaded: "Loading",
  totalCDBalance: 0,
  totalSavingsBalance: 0,
  totalBalance: 0,
  CDProductList: [],
  BumpProductList: [],
  SavingsProductList: [],
  isConsentRequired: false,
  consentRequiredAccounts: [mockbasicAccountInfo.data[0], mockbasicAccountInfo.data[2]],
  gotProductList: "Loading",
  gotTaxDocuments: "Loading",
  gotNotices: "Loading",
  gotAgreements: "Loading"
}

//API CALL TO GET ACCOUNT INFORMATION
export const getAccountInformationAsync = createAsyncThunk(
  "accountInformation/getAccountInformation_API",
  async (customerId: string) => {
    const response = await getAccountInformation_API(customerId);
    return response.data
  }
)

//API CALL TO UPDATE THE USERS ACCOUNT NICKNAME
// export const patchAccountNickName = createAsyncThunk(
//   "accountInformation/patchUserNickName_API",
//   async (apiData: { accountNumber: string, newNickName: string }) => {
//     const response = await patchAccountNickName_API(apiData.accountNumber, apiData.newNickName)
//     return response.data
//   }
// )

/**API call to get the products list to be displayed in products modal and maturity product selection page */
export const getProductsAsync = createAsyncThunk(
  "accountInformation/getProducts_API",
  async () => {
    const response = await getProductList_API()

    return response.data
  }
)

/**API Call to get a users tax documents */
export const getTaxDocumentsAsync = createAsyncThunk(
  "accountInformation/getTaxDocuments_API",
  async () => {
    const response = await getTaxDocuments_API()

    return response.data
  }
)

//The accountInformation object slice
export const accountInformationSlice = createSlice({
  name: 'accountInformation',
  initialState,
  reducers: {
    //the action payload is the array of accounts
    buildAccountInformation: (state, action: PayloadAction<any>) => {
      state.accountInformation = action.payload
    },
    //the action payload is an object with {arrayIndex, beneficiary array} that is used to update the array of beneficiaries for a account
    updateBeneficiaryArrayByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].beneficiaries = action.payload.beneficiaryArray
      state.accountInformation[action.payload.arrayIndex].gotBeneficiaryDetails = true
    },
    //the action payload is an object {arrayIndex, index, percentage} that is used to update the allocation for a particular beneficiary
    updateBeneficiaryAllocationByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].beneficiaries[action.payload.index].percentage = action.payload.percentage
    },
    //push a new beneficiary to the beneficiary array by accountInformation[index], action payload is arrayIndex and beneficiary
    addBeneficiaryByIndex: (state, action) => {
      // state.accountInformation[action.payload.arrayIndex].beneficiaries = [...state.accountInformation[action.payload.arrayIndex].beneficiaries, action.payload.beneficiary]
      state.accountInformation[action.payload.arrayIndex].beneficiaries.push(action.payload.beneficiary)
      state.accountInformation[action.payload.arrayIndex].isBeneficiariesLoaded = true
    },
    //the action payload is an aboject {arrayIndex, index} to set the isDeleted flag to false for a particular beneficiary
    setBeneficiaryDeletedFlagToFalseByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].beneficiaries[action.payload.index].isDeleted = false
    },
    //Updates a single beneficiary's information 
    updateBeneficiaryBySelectedAccountBeneficiaryIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].beneficiaries[action.payload.beneficiaryIndex] = action.payload.beneficiary
    },
    //the action payload is an index, updates the maturity option to "Renew"
    setMaturityOptionRenew: (state, action) => {
      state.accountInformation[action.payload].maturitySetting.option = "Renew"
    },
    //the action payload is an object with {arrayIndex, term}, which updates the selected term
    setMaturityOptionChange: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].maturitySetting.termLength = action.payload.term.slice(
        action.payload.term.length - 8,
        action.payload.term.length - 6
      );
      state.accountInformation[action.payload.arrayIndex].maturitySetting.option = "Change"
    },
    //the actin payload is an index, updates the maturity setting option
    setMaturityOptionClose: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].maturitySetting.option = "Close"
    },
    //the action payload is an object {arrayIndex, accountToTransferTo}, which sets the interest account to transfer to
    setAccountInformationIndexInterestTransfer: (state, action) => {
      let tempInterestAccount: ISelectedInterestAccount = { id: "", accountTitle: "", accountNumber: "", accountType: "" }
      if (action.payload.accountToTransferTo.accountType && ['CD', 'Savings'].some(e => e === action.payload.accountToTransferTo.accountType)) {
        tempInterestAccount = {
          id: action.payload.accountToTransferTo.id,
          accountTitle: action.payload.accountToTransferTo.accountTitle,
          accountNumber: action.payload.accountToTransferTo.accountNumber,
          accountType: action.payload.accountToTransferTo.accountType
        }
      }
      else {
        tempInterestAccount = {
          id: action.payload.accountToTransferTo.id,
          accountTitle: action.payload.accountToTransferTo.accountNickName,
          accountNumber: action.payload.accountToTransferTo.accountNickName.slice(action.payload.accountToTransferTo.accountNickName.length - 4, action.payload.accountToTransferTo.accountNickName.length),
          accountType: "External"
        }
      }

      state.accountInformation[action.payload.accountIndex].selectedInterestAccount = tempInterestAccount
    },
    //updates the account nickname, action payload is arrayIndex, and nickname
    updateAccountNickNameByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].accountTitle = action.payload.newName
    },
    //updates the transactions being loaded by index
    updateTransactionsLoadedByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].transactions = action.payload.transactions
      state.accountInformation[action.payload.arrayIndex].isTransactionsLoaded = true
    },
    //Updates the bump rate banner found on the account details page to true by index, action is arrayIndex
    updateBumpRateBannerByIndex: (state, action) => {
      state.accountInformation[action.payload].bannerDismissed = true
    },
    //Activates the bump rate by index, action payload is arrayIndex and bumpAPY
    activateBumpRateByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].interestRate = action.payload.bumpAPY
      state.accountInformation[action.payload.arrayIndex].isBannerAvailable = false
      state.accountInformation[action.payload.arrayIndex].bumpActivated = true
      state.accountInformation[action.payload.arrayIndex].bumpedRateDate = action.payload.date
    },
    //updates the maturity settings by index for the "Renew" selection, action is arrayIndex, term, type, and changePlan
    updatesMaturitySettingsRenewalByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].maturitySetting.option = action.payload.type
      state.accountInformation[action.payload.arrayIndex].maturitySetting.termLength = action.payload.term
      state.accountInformation[action.payload.arrayIndex].maturitySetting.changeProduct = action.payload.changePlan
      state.accountInformation[action.payload.arrayIndex].maturitySetting.transferAmount = action.payload.transferAmount
      state.accountInformation[action.payload.arrayIndex].maturitySetting.changeProductCode = action.payload.changePlanCode
    },
    /**updates the maturity settings by index for the "Close" selection, action is arrayIndex, and transfer account */
    updatesMaturitySettingsCloseByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].maturitySetting.option = "Close"
      state.accountInformation[action.payload.arrayIndex].maturitySetting.closedAccountName = action.payload.transferAccount
      state.accountInformation[action.payload.arrayIndex].maturitySetting.transferAmount = 0
      state.accountInformation[action.payload.arrayIndex].maturitySetting.transferType = ""
    },
    /**updates the maturity settings by index for the "transfer funds" selection, action is arrayIndex, transferAccount, transferAmount, transferType */
    updatesMaturitySettingsTransferFundsByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].maturitySetting.transferAccount = action.payload.transferAccount
      state.accountInformation[action.payload.arrayIndex].maturitySetting.transferId = action.payload.transferId
      state.accountInformation[action.payload.arrayIndex].maturitySetting.transferAmount = action.payload.transferAmount
      state.accountInformation[action.payload.arrayIndex].maturitySetting.transferType = action.payload.transferType
      state.accountInformation[action.payload.arrayIndex].maturitySetting.scheduledTransferId = action.payload.scheduledTransferId
    },
    /**removes a fund transfer from maturity settings by index, the action being sent in is the arrayIndex */
    removeMaturitySettingsFundTransferByIndex: (state, action) => {
      state.accountInformation[action.payload].maturitySetting.transferAccount = ""
      state.accountInformation[action.payload].maturitySetting.transferAmount = 0
      state.accountInformation[action.payload].maturitySetting.transferType = ""
    },
    /**Updates the early withdrawal data by index */
    setEarlyWithdrawalByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].earlyWithdrawal.transferAccountTitle = action.payload.accountTitle
      state.accountInformation[action.payload.arrayIndex].earlyWithdrawal.transferAccount = action.payload.accountLastFour
      state.accountInformation[action.payload.arrayIndex].earlyWithdrawal.transferAmount = action.payload.transferAmount
      state.accountInformation[action.payload.arrayIndex].earlyWithdrawal.transferDate = action.payload.transferDate
      state.accountInformation[action.payload.arrayIndex].earlyWithdrawalSelected = true
    },
    /**Updates the early withdrawal optional answer by index */
    setEarlyWithdrawalOptionalAnswerByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].earlyWithdrawal.optionalAnswer = action.payload.questionAnswer
    },
    /**Cancels the early withdrawal requst by index */
    cancelEarlyWithdrawalByIndex: (state, action) => {
      state.accountInformation[action.payload].earlyWithdrawal.transferAccountTitle = ""
      state.accountInformation[action.payload].earlyWithdrawal.transferAccount = ""
      state.accountInformation[action.payload].earlyWithdrawal.transferAmount = 0
      state.accountInformation[action.payload].earlyWithdrawal.transferDate = ""
      state.accountInformation[action.payload].earlyWithdrawal.optionalAnswer = ""
      state.accountInformation[action.payload].earlyWithdrawalSelected = false
    },
    /**Updates the selected term change found within the grace period flow */
    updateGraceTermChangeByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].graceTermRenewal = action.payload.termChange
      state.accountInformation[action.payload.arrayIndex].accountType = action.payload.accountType
      state.accountInformation[action.payload.arrayIndex].description = action.payload.description
      state.accountInformation[action.payload.arrayIndex].accountTitle = action.payload.description
      state.accountInformation[action.payload.arrayIndex].term = action.payload.term
      state.accountInformation[action.payload.arrayIndex].interestRate = action.payload.interestRate
      state.accountInformation[action.payload.arrayIndex].maturityDate = action.payload.maturityDate
      state.accountInformation[action.payload.arrayIndex].cdGroup = action.payload.cdGroup
      state.accountInformation[action.payload.arrayIndex].maturitySetting.option = action.payload.maturitySettings.option
      state.accountInformation[action.payload.arrayIndex].maturitySetting.termLength = action.payload.maturitySettings.termLength
      state.accountInformation[action.payload.arrayIndex].maturitySetting.changeProduct = action.payload.maturitySettings.changeProduct
    },
    /**updates the grace period settings by index for the "transfer funds" selection, action is arrayIndex, transferAccount, transferAmount, transferType */
    updatesGraceSettingsTransferFundsByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].gracePeriodFundTransfer.transferAccountId = action.payload.transferAccountId
      state.accountInformation[action.payload.arrayIndex].gracePeriodFundTransfer.transferId = action.payload.transferId
      state.accountInformation[action.payload.arrayIndex].gracePeriodFundTransfer.transferAccount = action.payload.transferAccount
      state.accountInformation[action.payload.arrayIndex].gracePeriodFundTransfer.transferAmount = action.payload.transferAmount
      state.accountInformation[action.payload.arrayIndex].gracePeriodFundTransfer.transferType = action.payload.transferType
    },
    /**removes a fund transfer from grace period by index, the action being sent in is the arrayIndex */
    removeGraceFundTransferByIndex: (state, action) => {
      state.accountInformation[action.payload].gracePeriodFundTransfer.transferAccountId = ""
      state.accountInformation[action.payload].gracePeriodFundTransfer.transferId = ""
      state.accountInformation[action.payload].gracePeriodFundTransfer.transferAccount = ""
      state.accountInformation[action.payload].gracePeriodFundTransfer.transferAmount = 0
      state.accountInformation[action.payload].gracePeriodFundTransfer.transferType = ""
    },
    /**Closes an account by index */
    closeAccountByIndex: (state, action) => {
      state.accountInformation[action.payload].pendingGraceClosure = true
      // state.accountInformation[action.payload].accountClosed = true
      // ! This date may need to be set to GMT using the ISO function, need to double check this with business when we put grace period closure bak in.
      // state.accountInformation[action.payload].accountClosedDate = convertDateTommddyyyy(new Date())
    },
    /**Populate the account transactions by index */
    setAccountTransactionsByIndex: (state, action) => {
      // state.accountInformation[action.payload.arrayIndex].transactions = action.payload.transactions
    },
    setAccountInformationByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex] = action.payload.account
      // state.accountInformation[action.payload.arrayIndex].gotAccountDetails = true
    },
    /**removes the bump banner from for a particular account in the account information array */
    removeBumpRateBannerByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].isBannerAvailable = action.payload.show
    },
    /**Updates the Product List */
    updateProductList: (state, action) => {
      // const sortedCDArray = action.payload.CDProductList.sort((a: IProduct, b: IProduct) => (+b.term > +a.term) ? -1 : 1)
      // const sortedBumpCds = action.payload.BumpProductList.sort((a: IProduct, b: IProduct) => (+b.term > +a.term) ? -1 : 1)
      // const sortedSavings = action.payload.SavingsProductList.sort((a: IProduct, b: IProduct) => (+b.term > +a.term) ? -1 : 1)
      // state.CDProductList = sortedCDArray
      // state.BumpProductList = sortedBumpCds
      // state.SavingsProductList = sortedSavings
    },
    /**Updates isConsentRequired key */
    updateIsConsentRequired: (state, action) => {
      state.isConsentRequired = action.payload
    },
    /**updates the account statements by index */
    updateAccountStatementsByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].accountStatements = populateStatements(action.payload.statements)
      state.accountInformation[action.payload.arrayIndex].gotAccountStatements = true
    },
    /**updates the account notices by index */
    updateAccountNoticesByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].accountNotices = populateAccountNotices(action.payload.notices)
      state.accountInformation[action.payload.arrayIndex].gotAccountNotices = true
    },
    /**updates the interest settings account information by index */
    updateAccountInterestSettingsByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].selectedInterestAccount = action.payload.account
      state.accountInformation[action.payload.arrayIndex].gotInterestSettings = true
    },
    /**Updates the maturity transfer account name by index */
    updateMaturityAccountNameByIndex: (state, action) => {

    },
    /**updates the account agreements by index */
    updateAccountAgreementsByIndex: (state, action) => {
      state.accountInformation[action.payload.arrayIndex].accountAgreements = action.payload.agreements
      state.accountInformation[action.payload.arrayIndex].gotAccountAgreements = true
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAccountInformationAsync.pending, (state) => {
        state.isAccountInformationLoaded = 'Loading';
      })
      .addCase(getAccountInformationAsync.fulfilled, (state, action) => {
        state.accountInformation = createAccountInformationObject(action.payload)
        const tempConsentArray = buildTermsAndConditions(action.payload.accounts)
        if (tempConsentArray.length > 0) {
          state.consentRequiredAccounts = tempConsentArray
          state.isConsentRequired = true
        }
        state.totalBalance = +(action.payload.totalBalance as string) || 0;
        state.totalSavingsBalance = +(action.payload.totalSavingsBalance as string) || 0;
        state.totalCDBalance = +(action.payload.totalCDBalance as string) || 0;
        state.isAccountInformationLoaded = 'Success';
      })
      .addCase(getAccountInformationAsync.rejected, (state) => {
        state.isAccountInformationLoaded = 'Failed';
      })



      // .addCase(patchAccountNickName.pending, (state) => { })
      // .addCase(patchAccountNickName.fulfilled, (state: any, action) => { })
      // .addCase(patchAccountNickName.rejected, (state) => { })



      .addCase(getProductsAsync.pending, (state) => { })
      .addCase(getProductsAsync.fulfilled, (state, action) => {
        state.gotProductList = "Success"
        const sortedCDArray = action.payload.jumboCD.sort((a: IProduct, b: IProduct) => (+b.term > +a.term) ? -1 : 1)
        const sortedBumpCds = action.payload.bumbupCD.sort((a: IProduct, b: IProduct) => (+b.term > +a.term) ? -1 : 1)
        const sortedSavings = action.payload.savings.sort((a: IProduct, b: IProduct) => (+b.term > +a.term) ? -1 : 1)
        for(let i = 0; i < sortedCDArray.length; i = i + 1){
          sortedCDArray[i].name = sortedCDArray[i].name.toLowerCase()
        }
        for(let i = 0; i < sortedBumpCds.length; i = i + 1){
          sortedBumpCds[i].name = sortedBumpCds[i].name.toLowerCase()
        }
        state.CDProductList = sortedCDArray
        state.BumpProductList = sortedBumpCds
        state.SavingsProductList = sortedSavings
      })
      .addCase(getProductsAsync.rejected, (state) => {
        state.gotProductList = "Failed"
      })
      .addCase(getTaxDocumentsAsync.pending, (state) => {
        state.gotTaxDocuments = "Loading"
      })
      .addCase(getTaxDocumentsAsync.fulfilled, (state, action) => {
        state.gotTaxDocuments = "Success"
        state.accountInformation[0].taxDocuments = populateTaxDocuments(action.payload.documentIds)
      })
      .addCase(getTaxDocumentsAsync.rejected, (state) => {
        state.gotTaxDocuments = "Failed"
      })
  },
});

export const accountInformation = (state: RootState) => state.accountInformation
export const { buildAccountInformation, setMaturityOptionRenew, setMaturityOptionChange, setMaturityOptionClose, setAccountInformationIndexInterestTransfer,
  updateBeneficiaryArrayByIndex, updateBeneficiaryBySelectedAccountBeneficiaryIndex, addBeneficiaryByIndex, removeMaturitySettingsFundTransferByIndex,
  updateAccountNickNameByIndex, updateTransactionsLoadedByIndex, updatesMaturitySettingsRenewalByIndex, updatesMaturitySettingsCloseByIndex,
  updatesMaturitySettingsTransferFundsByIndex, activateBumpRateByIndex, updateBumpRateBannerByIndex, setEarlyWithdrawalByIndex, cancelEarlyWithdrawalByIndex,
  setEarlyWithdrawalOptionalAnswerByIndex, updateGraceTermChangeByIndex, updatesGraceSettingsTransferFundsByIndex,
  removeGraceFundTransferByIndex, closeAccountByIndex, setAccountTransactionsByIndex, setAccountInformationByIndex, removeBumpRateBannerByIndex, updateProductList,
  updateIsConsentRequired, updateAccountStatementsByIndex, updateAccountNoticesByIndex, updateAccountInterestSettingsByIndex, updateMaturityAccountNameByIndex,
  updateAccountAgreementsByIndex, setBeneficiaryDeletedFlagToFalseByIndex, updateBeneficiaryAllocationByIndex } = accountInformationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default accountInformationSlice.reducer;
