import './DesktopBeneficiaryDelete.scss'
import { IDesktopBeneficiaryDeleteProps } from './interfaces/IDesktopBeneficiaryDeleteProps'
import deletedBeneficiary from '../../../assets/deletedBeneficiary.svg'
import { useBeneficiaryDelete } from '../../../useHooks/componentHooks/useBeneficiaryDelete/useBeneficiaryDelete'

/**
 * The component is used to delete a beneficiary from an account
 */
const DesktopBeneficiaryDelete = (props: IDesktopBeneficiaryDeleteProps) => {
    const { setDeleteBeneficiary, selectedBeneficiary, setEditBeneficiary, setEditProfile, setShowAlertMessage, setCheckAllocations, setOnDelete } = props
    const { handleDeleteBeneficiary, modalRef } = useBeneficiaryDelete(setDeleteBeneficiary, setEditBeneficiary, setEditProfile, setShowAlertMessage, selectedBeneficiary, setCheckAllocations)

    return (
        <>
        <div className="Delete-Background-Overlay" onClick={() => setDeleteBeneficiary(false)} data-testid={'bg-overlay'}/>
        <div className="Delete-Beneficiary-Card-Wrapper" ref={modalRef} >
            <div className="Card-Details">
                <div className="Icon">
                    <img className="Delete-Icon" src={deletedBeneficiary} alt="Delete Beneficiary Icon" />
                </div>
                <div className="Top-Message">
                    {selectedBeneficiary?.type === "Person" && <span className="Bolded-Message h4">Are you sure you want to remove {selectedBeneficiary.firstName} {selectedBeneficiary.lastName} as a beneficiary from this account? </span>}
                    {(selectedBeneficiary?.type === "Charity" || selectedBeneficiary.type === "Non Profit") && <span className="Bolded-Message h4">Are you sure you want to remove {selectedBeneficiary.charityName} as a beneficiary from this account? </span>}
                    {selectedBeneficiary?.type === "Trust" && <span className="Bolded-Message h4">Are you sure you want to remove {selectedBeneficiary.trustName} as a beneficiary from this account? </span>}
                </div>
                <div className="Bottom-Message"> 
                    <span className="Text-Message regular">This beneficiary will be removed immediately. You can’t undo this action.</span>
                </div>
                <div className="Button-Wrap">
                    <button className="Cancel-Button boldButtonLarge" onClick={() => {setDeleteBeneficiary(false); document.body.style.overflow = "auto"}}>Cancel</button>
                    <button className="Remove-Button boldButtonLarge" onClick={() => handleDeleteBeneficiary(setOnDelete)}>Remove</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default DesktopBeneficiaryDelete