import { useEffect, useRef, useState } from "react"
import { setCheckInternalToken, setOTPMenuStatus, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"
import { useAppDispatch } from "../../../app/hooks"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { postFactorSelection_API, postVerificationCode_API } from "../../../api/User/otpServices"
import { IFactor } from "../useOTPModal/useOTPModal"

/**A custom use hook holding the logic for the VerificationCodeInput component */
export const useVerificationCodeInput = (
        setStep: React.Dispatch<React.SetStateAction<number>>,
        factorOptions: Array<IFactor>
    ) => {
    const { windowState } = useWindowState()
    const [inputCode, setInputCode] = useState<string>("")
    const [status, setStatus] = useState<"Pass" | "Fail" | "">("")
    const [validEntry, setValidEntry] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const [codeResent, setCodeResent] = useState<boolean>(false)
    const [resendTimer, setResendTimer] = useState<number>(30)
    const [clearInputs, setClearInputs] = useState<boolean>(false)
    const [failCount, setFailCount] = useState<number>(0)
    const verifyClickedRef = useRef<boolean>(false)
    const resendClickedRef = useRef<boolean>(false)

    /**Checks the failed input count, if failCount === 5 then we advance to the next step which is lock the users account */
    // useEffect(() => {
    //     if(failCount >= 5){
    //         setStep(3)
    //     }
    // }, [failCount, setStep])

    /**Checks for a valid input when the inputCode changes */
    useEffect(() => {
        let invalid = false
        for(let i = 0; i < inputCode.length; i = i + 1){
            if(inputCode[i] === " "){
                invalid = true
            }
        }
        if(!invalid && inputCode.length === 6){
            setValidEntry(true)
        }
        else{
            setValidEntry(false)
        }
    }, [inputCode])

    /**handles the Verify Code onClick event */
    const handleVerify = async (chosenFactor: IFactor) => {
        if(!verifyClickedRef.current){
            verifyClickedRef.current = true
            try{
                const response = await postVerificationCode_API(chosenFactor.id, inputCode, factorOptions)
                localStorage.setItem("verifyToken", response.headers.stepuptoken)
                localStorage.setItem("timerToken", response.data.mfaToken)
                setStatus("Pass")
                setFailCount(0)
                setStep(1)
                dispatch(setOTPPassed(true))
                dispatch(setOTPMenuStatus(false))
                dispatch(setCheckInternalToken(false))
               
            }
            catch(error: any){
             
                let errorReturn: any = error.response ? JSON.parse(JSON.stringify(error.response)) : ""
                if(errorReturn !== "" && errorReturn.data.errorMsg[0].includes("title=That code doesn’t look right")){
                    setStatus("Fail")
                    // setFailCount(failCount + 1)
                }
                else if(errorReturn !== "" && errorReturn.data.errorMsg[0].includes("title=Your account has been locked")){
                    console.log("THE ACCOUNT HAS BEEN LOCKED")
                    setStep(3)
                }
                else{
                    console.log("It failed for another reason.")
                }

            }
            verifyClickedRef.current = false
            return Promise.resolve(true);
        }
    }

    /**handles the cancel button onClick event */
    const handleCancel = () => {
        setStep(1)
        dispatch(setOTPMenuStatus(false))
    }

    /**handles the resend button onClick event */
    const handleResend = async (chosenFactor: IFactor) => {
        if(!resendClickedRef.current){
            resendClickedRef.current = true
            try{
                await postFactorSelection_API(chosenFactor.id)
                setCodeResent(true)
                setClearInputs(true)
                setValidEntry(false)
                setStatus("")
                setFailCount(0)
            }
            catch{
                console.log("FAILED TO RESEND THE VERIFICATION CODE")
            }
            resendClickedRef.current = false
        }
    }
    
    /**Handles the code resend countdown timer */
    useEffect(() => {
        if(codeResent && resendTimer > 0){
            setTimeout(() => {
                setResendTimer(resendTimer -1)
            }, 1000)
        }
        else {
            setCodeResent(false)
            setResendTimer(30)
        }
    }, [codeResent, resendTimer])

    return { windowState, inputCode, setInputCode, status, validEntry, handleVerify, handleCancel, handleResend, codeResent, resendTimer, clearInputs, setClearInputs }
}