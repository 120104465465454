import './DesktopBeneficiaryEdit.scss';
import { useBeneficiaryEdit } from '../../../../useHooks/componentHooks/useBeneficiaryEdit/useBeneficiaryEdit';
import { IBeneficiary } from '../../../../api/getBeneficiaries';
import { IDesktopBeneficiaryEditProps } from './interfaces/IDesktopBeneficiaryEditProps';
import DisplayBeneficiaryRow from './components/DisplayBeneficiaryRow/DisplayBeneficiaryRow';
// import DesktopUnsavedChanges from './components/DesktopUnsavedChanges/DesktopUnsavedChanges';
import arrowLeft from '../../../../assets/arrowLeft.svg'
import { Button, TIMER_IDS } from '../../../Button/Button';
import BeneDeleteConfirmation from '../../../../Pages/Beneficiary/components/BeneDeleteConfirmation/BeneDeleteConfirmation';
import { AccountHeader } from '../../../AccountHeader/AccountHeader';

/**
 * The component displays the edit allocation for the desktop version of edit beneficiaries.
 */
const DesktopBeneficiaryEdit = (props: IDesktopBeneficiaryEditProps) => {
  const {
    editBeneficiary,
    setEditBeneficiary,
    showAlertMessage,
    setShowAlertMessage,
    childRef,
    checkAllocations,
    setCheckAllocations,
    handleOnNext,
    onAddBeneficiary,
    newBeneficiary,
    handleOnReset = () => { },
    onDelete = false,
    setOnDelete,
    selectedBeneficiary,
    showDeleteConfirmationPage, 
    setShowDeleteConfirmationPage
  } = props;
  const {
    successfulAllocation,
    editBeneficiaryArray,
    onChangeHandler,
    totalPercentage,
    saveChanges,
    checkAllocationsOnCancel,
    selectedAccount,
    zeroValueError,
    sumError,
    previousPage,
    resetBeneficiaryArray,
  } = useBeneficiaryEdit(showAlertMessage as any, setShowAlertMessage, setEditBeneficiary, childRef, checkAllocations, setCheckAllocations, newBeneficiary as IBeneficiary, setOnDelete as any, setShowDeleteConfirmationPage as any);

  const onReset = () => {
    resetBeneficiaryArray(onDelete ? true : false);
    document.body.style.overflow = "auto";
    handleOnReset();
  }

  if(showDeleteConfirmationPage){
    return (
            <>
            <AccountHeader 
              returnMessage={'Account Settings'}
              setDisplayDetails={false}            
            />
            <BeneDeleteConfirmation 
              selectedBeneficiary={selectedBeneficiary as IBeneficiary}
              setBeneficiaryEdit={setEditBeneficiary}
              setShowDeleteConfirmationPage={setShowDeleteConfirmationPage as any}
            />
            </>
    )
  }

  return (
    <div className="card-wrapper beneficiary-edit ">
      {!onAddBeneficiary &&
        <div className="basic-account-header">
          <div className='data-wrapper'>
            <div className="return-wrapper" onClick={() => checkAllocationsOnCancel("Settings")}>
              <img className="return-arrow" src={arrowLeft} alt="Back button" />
              <span className="return-text regular">{previousPage || "Account Settings"}</span>
            </div>
            <div>
              <span className="account-description-text mobileh2">{selectedAccount?.accountTitle}</span>
              <span className="split-pipe">|</span>
              <span className="account-number regular">{selectedAccount?.accountType} {" "}
                {selectedAccount.accountNumber?.slice(selectedAccount?.accountNumber.length - 4, selectedAccount.accountNumber.length)}
              </span>
            </div>
          </div>
        </div>
      }
      <div className={!onAddBeneficiary ? "container" : "container add-flow"}>
        <div className={!onAddBeneficiary ? "desktop" : "desktop add-flow"}>

          <div className="title-lockup">
            {!onAddBeneficiary ?
              <>
                {!onDelete ?
                  <div className="pre-header"><div className="content">Beneficiaries</div></div>
                  :
                  <div className="pre-header"><div className="content">Remove beneficiary</div></div>
                }
              </>
              :
              <div className="pre-header"><div className="content">Add beneficiary</div><div className="step-count">Step 4 of 4</div></div>
            }
            <h2>Adjust allocations</h2>
          </div>
          <div className="grey-line" />
          {!onDelete ?
            <span className="instruction-text regular">Allocations designate what percentage of your assets the beneficiary should receive. <br />Each beneficiary must have at least 1%, and the total must add up to 100%.</span>
            :
            <span className="instruction-text regular">Adjust the allocations for your remaining beneficiaries so they equal 100%. <br />Then, select 'Save' to finish removing your beneficiary.</span>
          }
          <div className="Beneficiary-Details-Wrapper">
            <div className="data-wrap">
              <div className="Header-Line">
                <span className="Name-Text small">Beneficiary</span>
                <span className="Allocation-Text small">Allocation</span>
              </div>
              {editBeneficiaryArray.map(
                (beneficiary: IBeneficiary, index: number) => {
                  return (
                    <div key={index} className="beneficiary-row-wrapper">
                      {!beneficiary.isDeleted &&
                        <DisplayBeneficiaryRow
                          index={index}
                          beneficiary={beneficiary}
                          onChangeHandler={onChangeHandler}
                        />
                      }
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="Total-Wrapper">
            <div className="allocations-wrap">
              <span className="Totals semibold">Total Allocation </span>
              <div className="bottom-total">
                <span className={successfulAllocation ? "Totals semiboldLarge" : "Totals semiboldLarge error"}>{totalPercentage}</span>
                <div className="allocation-percentage boldRegular">%</div>
              </div>
            </div>
            <div className="errors-wrap">
              {/* {sumError && <span className="sum-error-text small">You have {100 - totalPercentage}% left to allocate</span>} */}
              {(zeroValueError || sumError) && (totalPercentage !== 100) && <span className="zero-error-text boldSmall">Total allocation must equal 100%</span>}
            </div>
          </div>
          <div className="control-bar">
            <div className="button-wrap">
              <div className="btn-group">
                <Button
                  className="Button btn-secondary"
                  onClick={() => {checkAllocationsOnCancel("Cancel"); onReset()}}
                  title="Cancel"
                />
                <Button
                  className={
                    successfulAllocation
                      ? 'Button btn-primary'
                      : 'Button btn-primary inactive'
                  }
                  onClick={() => {saveChanges(handleOnNext, onAddBeneficiary ? true : false)}}
                  title="Save"
                  isDisabled={!successfulAllocation}
                  id={TIMER_IDS.BENEFICIARY_ALLOCATIONS}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {unsavedChanges &&
        <DesktopUnsavedChanges
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          setEditBeneficiary={setEditBeneficiary}
          onReset={onReset}

        />
      } */}

    </div>
  );
};

export default DesktopBeneficiaryEdit;
