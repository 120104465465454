import { ENV, getEnvironment } from './getEnvironment'

/**
 * Loads the scripts for adobe analytics
 */
export const loadGlassboxScript = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  let script = document.createElement('script')
  script.id = '_cls_detector'
  script.dataset.clsconfig = 'reportURI=https://report.creditone.glassboxdigital.io/glassbox/reporting/11c641e0-7e8d-928c-74ca-86e5c6d73d48/cls_report; recordScrolls=true;recordMouseMoves=true;idleEventTimeInterval=-1;'
  script.async = true
  if (getEnvironment() === ENV.PROD) {
    script.src = `https://cdn.glassboxcdn.com/creditone/digitalbank_web/p/detector-dom.min.js`;
  }
  else {
    script.src = `https://cdn.glassboxcdn.com/creditone/digitalbank_web/u/detector-dom.min.js`;
  }

  document.head.appendChild(script)
}

