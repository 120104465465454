import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { IAccountInformation } from "../../../api/getAccountInformation"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { updateMaturitySettingsOnClose } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { setSelectedAccount } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { setCheckInternalToken, setOTPPassed } from "../../../slices/userInformation/userInformationSlice"

/**A custom use hook holding the logic for the transfer at maturity component */
export const useTransferAtMaturity = (
        setPlanChange: any, 
        setShowTermChange: any, 
        setChangePlanOption: any,
        transferAccount: {id: string, title: string, value: string, accountType: "external" | "internal" | ""},
        setTransferAccount: React.Dispatch<React.SetStateAction<{id: string, title: string, value: string, accountType: "external" | "internal" | ""}>>
    ) => {
    const { windowState } = useWindowState()
    const history = useHistory()
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const [foundSavings, setFoundSavings] = useState<boolean>(false)
    const linkedExternalAccounts = useAppSelector((state: RootState) => state.userInformation.externalAccounts)
    const [linkedAccounts, setLinkedAccounts] = useState<Array<{id: string, title: string, value: string, accountType: "external" | "internal" | ""}>>([])
    const dispatch = useAppDispatch()
    const [openSavings, setOpenSavings] = useState<boolean>(false)
    const [savingsPageClosed, setSavingsPageClosed] = useState<boolean>(true)
    const foundInternalAccount = useRef<boolean>(false)
    const [enableSave, setEnableSave] = useState<boolean>(false)
    const linkExternalClicked = useRef<boolean>(false)
    const userInformation = useAppSelector((state: RootState) => state.userInformation)
    const [showLimitModal, setShowLimitModal] = useState<boolean>(false)
    const OTPPassed = useAppSelector((state: RootState) => state.userInformation.OTPPassed)

    /**Build the linked accounts array for the accounts dropdown selection */
    const buildLinkedAccounts = () => {
        let tempAccounts: Array<{title: string, value: string, id: string, accountType: "external" | "internal" | ""}> = []
        let addAccount = {id: "", title: "CREDIT ONE ACCOUNTS", value: "", accountType: "" as "external" | "internal" | ""}
        tempAccounts.push(addAccount)
        for(let i = 0; i < accountInformation.length; i = i + 1){
            if(accountInformation[i].accountType === "Savings" && !accountInformation[i].accountClosed){
                if(transferAccount?.id === "" && !foundInternalAccount.current){
                    foundInternalAccount.current = true
                    setTransferAccount({id: accountInformation[i].id, title: accountInformation[i].accountTitle, value: accountInformation[i].accountNumber, accountType: "internal"})
                }
                let addAccount = {id: accountInformation[i].id, title: accountInformation[i].accountTitle, value: accountInformation[i].accountNumber, accountType: "internal" as "external" | "internal" | ""}
                tempAccounts.push(addAccount)
            }
        }
        addAccount = {id: "", title: "EXTERNAL ACCOUNTS", value: "", accountType: "" as "external" | "internal" | ""}
        tempAccounts.push(addAccount)
        for(let i = 0; i < linkedExternalAccounts.length; i = i + 1){
            if(linkedExternalAccounts[i].status === "Linked"){
                if(i === 0 && transferAccount.id === "" && !foundInternalAccount.current){
                    setTransferAccount({id: linkedExternalAccounts[i].id, title: linkedExternalAccounts[i].accountNickName, value: "", accountType: "external"})
                }
                let addAccount = {id: linkedExternalAccounts[i].id, title: linkedExternalAccounts[i].accountNickName, value: "", accountType: "external" as "external" | "internal" | "" }
                tempAccounts.push(addAccount)
            }
        }
        if(tempAccounts.length){
            setLinkedAccounts(tempAccounts)
        }
    }
    
    /**On render check the account information array for a savings account */
    useEffect(() => {
        let savingsAccountFound = false
        for(let i = 0; i < accountInformation.length; i = i + 1){
            if(accountInformation[i].accountType === "Savings" && !accountInformation[i].accountClosed){
                /**Uncomment this later when we are not forcing the open savings account page to appear */
                savingsAccountFound = true
                setFoundSavings(true)
                setSavingsPageClosed(true)
            }
        }

        if(!savingsAccountFound){
            setSavingsPageClosed(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation])

    /**Build out the linked accounts array for the drop down once accountINformation and linked external accounts are here */
    useEffect(() => {
        if(accountInformation.length){
            buildLinkedAccounts()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation, linkedExternalAccounts])

    /**Handle the onClick of the continue button */
    const handleContinue = () => {
        dispatch(updateMaturitySettingsOnClose(transferAccount))
        setShowTermChange(true)
    }

    /**Handle the onClick for the cancel button */
    const handleCancel = () => {
        const index = accountInformation.findIndex((account: IAccountInformation) => account.accountNumber === selectedAccount.accountNumber)
        dispatch(setSelectedAccount(accountInformation[index]))
        setTransferAccount({id: "", title: "", value: "", accountType: ""})
        setPlanChange(false)
        setChangePlanOption("")
    }

    /**check to make sure the transfer to account is populated before enabling the continue button */
    useEffect(() => {
        if(transferAccount && transferAccount?.id !== ""){
            setEnableSave(true)
        }
        else{
            setEnableSave(false)
        }

    }, [transferAccount])

    /**handles the link external account button click */
    const handleLinkExternalAccount = () => {
        if(userInformation.externalAccounts.length > 2){
            setShowLimitModal(true)
          }
          else{
              linkExternalClicked.current = true
              dispatch(setCheckInternalToken(true))
              dispatch(setOTPPassed(false))
          }      
    }

    /**monitors the success of the otpModal and sets the openEditPage state to open once the OTP passes */
    useEffect(() => {
        if(OTPPassed && linkExternalClicked.current){
            linkExternalClicked.current = false
            dispatch(setOTPPassed(false))
            history.push("/linkexternalaccount")
        }   
          
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPPassed])

    /**make sure the checkInternalToken key is set to false on render */
    useEffect(() => {
        dispatch(setCheckInternalToken(false))
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
      
    return { foundSavings, linkedAccounts, history, handleContinue, handleCancel, setFoundSavings,
             openSavings, setOpenSavings, windowState, selectedAccount, savingsPageClosed, setSavingsPageClosed, enableSave, 
             showLimitModal, setShowLimitModal, handleLinkExternalAccount }
}