/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from "react"
import { IBeneficiary, patchBeneficiaryAllocations_API } from "../../../api/getBeneficiaries"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useWindowState } from "../../../Context/AccountContext/useWindowState"
import { removeBeneficiary, setUpdatedBeneficiaryArray } from "../../../slices/selectedAccountSlice/selectedAccountSlice"
import { useToastMessage } from "../../../Context/ToastContext/useToastContext"
import { updateBeneficiaryArrayByIndex } from "../../../slices/accountInformation/accountInformationSlice"
import { IAccountInformation } from "../../../api/getAccountInformation"
import { getEventPath } from "../../../utils/getEventPath"

/**
 * custom use hook for the delete BeneficiaryDelete component
 */
export const useBeneficiaryDelete = (setDeleteBeneficiary: any, setEditBeneficiary: any, setEditProfile:any, setShowAlertMessage: any, selectedBeneficiary: IBeneficiary, setCheckAllocations: any) => {
    const { windowState } = useWindowState()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const dispatch = useAppDispatch();  
    const modalRef = useRef<any>()
    const { type, message, resetToast, setToastMessage } = useToastMessage()
    const editBeneficiaryArray = JSON.parse(JSON.stringify(selectedAccount.beneficiaries))
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)

    /**Lock the ablity to scroll when the modal renders */
    useEffect(() => {
        document.body.style.overflow = "hidden"
    },[])



    /**Checks for click outside of delete beneficiary modal, if ouside then the modal is closed */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const path = getEventPath(event)
            if(windowState !== "Mobile"){
                if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("Delete-Beneficiary-Card-Wrapper")) === -1){
                    document.body.style.overflow = "auto";
                    // setDeleteBeneficiary(false)
                }
            }
            else{
                if(Array.from(path).findIndex((e: any) => e.classList && Array.from(e.classList).includes("Mobile-Delete-Beneficiary-Wrapper")) === -1){
                    document.body.style.overflow = "auto";
                    // setDeleteBeneficiary(false)
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Updates beneficiary account information
     * @param beneficiaryIndex index of the selected beneficiary in the array
     * @param NEW_BENEFICIARY_ARRAY array of beneficiaries
     */
    const updateBeneficiaries = async (beneficiaryIndex: number, NEW_BENEFICIARY_ARRAY: IBeneficiary[]) => {
        await patchBeneficiaryAllocations_API(selectedAccount.id, NEW_BENEFICIARY_ARRAY, accountInformation)
        await dispatch(removeBeneficiary(beneficiaryIndex))
        for(let i = 0; i < NEW_BENEFICIARY_ARRAY.length; i = i + 1){
            if(NEW_BENEFICIARY_ARRAY[i].isDeleted){
                NEW_BENEFICIARY_ARRAY.splice(i, 1)
            }
        }
        /**Updates the Selected Account */
        await dispatch(setUpdatedBeneficiaryArray(NEW_BENEFICIARY_ARRAY))
        let accountIndex = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
        const payload = {arrayIndex: accountIndex, beneficiaryArray: NEW_BENEFICIARY_ARRAY}
        /**Updates the Account Information Array */
        await dispatch(updateBeneficiaryArrayByIndex(payload))
    }

    /**
     * Deletes the selected beneficiary from the selectedAccount beneficiary array
     */
    const handleDeleteBeneficiary = async (setOnDelete: React.Dispatch<React.SetStateAction<boolean>>) => {
        document.body.style.overflow = "auto";
        //!Add an API call to delete the beneficiary
        let beneficiaryIndex = selectedAccount.beneficiaries.findIndex((beneficiary:IBeneficiary) => beneficiary.id === selectedBeneficiary.id)
        let tempBeneficiaryArray = editBeneficiaryArray
        try {
            //!Leave these two lines commented out for the time being.
            //Calls the selectedAccount reducer to remove a beneficiary by index
            setDeleteBeneficiary(false)
            setEditProfile(false)
            //!I am setting the delete to a success, when there is an API call the success/failure must be linked to the outcome of the API
            setShowAlertMessage("Deleted Success")
            if(selectedAccount.beneficiaries.length >= 3) {
                await dispatch(removeBeneficiary(beneficiaryIndex))
                setEditBeneficiary(true);
                setCheckAllocations(true)
                setOnDelete(true)
            }
            if(selectedAccount.beneficiaries.length === 2) {
                const NEW_BENEFICIARY_ARRAY:Array<IBeneficiary> = JSON.parse(JSON.stringify(tempBeneficiaryArray));
                NEW_BENEFICIARY_ARRAY[beneficiaryIndex].isDeleted = true;
                NEW_BENEFICIARY_ARRAY[beneficiaryIndex === 0 ? 1 : 0].percentage = 100;
                await updateBeneficiaries(beneficiaryIndex, NEW_BENEFICIARY_ARRAY)
                setToastMessage("SUCCESS", "Beneficiary removed.")
                resetToast()
            }
            if(selectedAccount.beneficiaries.length === 1) {
                const NEW_BENEFICIARY_ARRAY:Array<IBeneficiary> = JSON.parse(JSON.stringify(tempBeneficiaryArray));
                NEW_BENEFICIARY_ARRAY[0].isDeleted = true
                await updateBeneficiaries(beneficiaryIndex, NEW_BENEFICIARY_ARRAY)
                setToastMessage("SUCCESS", "Beneficiary removed.")
                resetToast()
            }
        }
        catch {
            setToastMessage("ERROR", "Unable to save changes. Please try again later.")
            resetToast()
        }
    }

    return { handleDeleteBeneficiary, modalRef, type, message }
}