import TransfersDropDown from '../../../../components/TransfersDropDown/TransfersDropDown'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
import { useCreateTransferLanding } from '../../../../useHooks/componentHooks/useCreateTransferLanding/useCreateTransferLanding'
import './CreateTransferLanding.scss'
import { ICreateTransferLandingProps } from './interfaces/ICreateTransferLandingProps'
import bankBlue from '../../../../assets/bankBlue.svg'
import blueSettings from '../../../../assets/blueSettings.svg'
import redExclamationCircle from '../../../../assets/redExclamationCircle.svg'
import customCalender from '../../../../assets/customCalendar.svg'
import MaxAccountsModal from '../../../UserProfile/components/MaxAccountsModal/MaxAccountsModal'

/**Renders the transfer landing page, so the page in the top card of Transfers that shows the transfer accounts drop downs */
const CreateTransferLanding = (props: ICreateTransferLandingProps) => {
    const { windowState } = useWindowState()
    const { selectedFromAccount, setSelectedFromAccount, selectedToAccount, setSelectedToAccount, selectionOptions,
        transferAmount, setTransferAmount, transferDate, setTransferDate, setTopCardIndex, topCardIndex, fromDetails } = props
    const { transferToArray, amountChangeHandler, handleOnKeyDown, amountInputError, maxTransferError, dateChangeHandler, dateInputError, history, enableContinue, minDate,
        isAmountErrored, handleContinue, gotExternalAccounts, handleLinkExternalAccount, maxAccountError, showMaxExternalAccountsModal, setShowMaxExternalAccountsModal } = useCreateTransferLanding(selectedFromAccount, selectionOptions, selectedToAccount, setSelectedToAccount, setTransferAmount, transferAmount, transferDate, setTransferDate, topCardIndex, setTopCardIndex, setSelectedFromAccount)


    /** Changes the route from savings account details page to the confirm transfers page, after make a transfer button is clicked. */
    const handleTransferFromSavingsDetails = () => {
        history.push("/transfers", {
            topCardIndex: 1,
            selectedToAccount: selectedToAccount,
            selectedFromAccount: selectedFromAccount,
            transferAmount: transferAmount,
            transferDate: transferDate
        });
    };

    return (
        <>
            {showMaxExternalAccountsModal &&
                <MaxAccountsModal setShowMaxAccountsModal={setShowMaxExternalAccountsModal} />
            }
            {windowState !== "Mobile" ?
                <div className="desktop-wrap">
                    <div className="title-lockup">
                        {!fromDetails ?
                            <>
                                <h1 className="title-text">Make a transfer</h1>
                                <p className="sub-title-text regular">Transfer money between your Credit One or external accounts.</p>
                            </>
                            :
                            <span className="title-text h4">Make a transfer</span>
                        }
                    </div>
                    {gotExternalAccounts === "Failed" &&
                        <div className="error-wrapper">
                            <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                            <div className="message-stack">
                                <span className="header-text boldLarge">Unable to load External Accounts</span>
                                <span className="sub-header-text regular">Please wait a moment and try again.</span>
                            </div>
                        </div>
                    }
                    <div className={!fromDetails ? "inner-card-wrap" : "inner-card-wrap details"}>
                        <div className={!fromDetails ? "first-row" : "first-row details"}>
                            <TransfersDropDown
                                testId={"Transfer from dropdown"}
                                label="Transfer from"
                                isRequired={true}
                                defaultValue="Select an account"
                                selectedOption={selectedFromAccount}
                                setSelectedOption={setSelectedFromAccount}
                                selectOptions={selectionOptions}
                                className="small"
                            />
                            <TransfersDropDown
                                testId={"Transfer to dropdown"}
                                label="Transfer to"
                                isRequired={true}
                                defaultValue="Select an account"
                                selectedOption={selectedToAccount}
                                setSelectedOption={setSelectedToAccount}
                                removeOption={selectedFromAccount}
                                className="small"
                            />
                        </div>
                        <div className={!fromDetails ? "second-row" : "second-row details"}>
                            <div className={!fromDetails ? "second-row-inputs-wrap" : "second-row-inputs-wrap details"}>
                                <label className={!fromDetails ? "label-wrap" : "label-wrap details"}>
                                    <div className="label-text-wrap">
                                        <span className="amount-text small">Amount</span>
                                        <span className="require-star">*</span>
                                    </div>
                                    <div className={isAmountErrored ? "input-box-wrap error" : "input-box-wrap"}>
                                        <span style={{
                                            visibility: transferAmount && transferAmount > 0 ? 'visible' : 'hidden'
                                        }} className="regular">$</span>
                                        <input
                                            className="input-box regular"
                                            type="number"
                                            placeholder="$0.00"
                                            value={transferAmount as number}
                                            onChange={(e: any) => amountChangeHandler(e)}
                                            onKeyDown={(e: any) => handleOnKeyDown(e)}
                                        />
                                    </div>
                                    {amountInputError && <span className="error-text mobileSemiboldError">Amount cannot be more than your available balance.</span>}
                                    {maxTransferError && <span className="error-text mobileSemiboldError">Amount must be less than $1,100,000.</span>}
                                    {maxAccountError && <span className="error-text mobileSemiboldError">Internal Savings accounts cannot exceed $1,500,000.</span>}
                                </label>
                                <label className={!fromDetails ? "label-wrap" : "label-wrap details"}>
                                    <div className="label-text-wrap">
                                        <span className="amount-text small">Transfer date</span>
                                        <span className="require-star">*</span>
                                    </div>
                                    <div className={dateInputError ? "input-box-wrap error" : "input-box-wrap"}>
                                        <input
                                            className="input-box regular"
                                            type="date"
                                            placeholder="Today"
                                            value={transferDate}
                                            onChange={(e: any) => dateChangeHandler(e)}
                                            min={minDate}
                                        />
                                    </div>
                                    {dateInputError && <span className="error-text mobileSemiboldError">The Selected Date Cannot Be Less Than Today's Date</span>}
                                </label>
                            </div>
                            <div className="transfers-text-wrap">
                                <p className="transfers-text small">Transfers submitted after 5 p.m. Pacific time or on a non-business day will be scheduled for the next business day.</p>
                            </div>
                        </div>
                        {!fromDetails &&
                            <>
                                <div className="border" />
                                <div className="nav-wrap">
                                    <div className="nav-option" onClick={() => handleLinkExternalAccount()}>
                                        <img src={bankBlue} alt="link external account" />
                                        <span className="nav-option-text small">Link external account</span>
                                    </div>
                                    <div className="nav-option" onClick={() => { history.push('/user/profile/external-accounts', { page: "Transfers" }) }}>
                                        <img src={blueSettings} alt="manage external account" />
                                        <span className="nav-option-text small">Manage external accounts</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {!fromDetails ?
                        <div className="button-wrap">
                            {/* <button className={enableContinue ? "Button btn-primary" : "Button btn-primary inactive"} onClick={() => {if(setTopCardIndex && topCardIndex && enableContinue){setTopCardIndex(topCardIndex + 1)}}}>Continue</button> */}
                            <button className={enableContinue ? "Button btn-primary" : "Button btn-primary inactive"} onClick={() => handleContinue()}>Continue</button>
                        </div>
                        :
                        <div className="button-wrap details">
                            <button onClick={handleTransferFromSavingsDetails} className={enableContinue ? "Button btn-primary details" : "Button btn-primary inactive details"} >Make a transfer</button>
                        </div>
                    }
                </div>
                :
                <div className="mobile-wrap">
                    {!fromDetails ?
                        <div className="title-lockup">
                            <span className="title-text h3">Make a transfer</span>
                            <span className="sub-title-text small">Transfer money between your Credit One or external accounts.</span>
                        </div>
                        :
                        <span className="title-text h4">Make a transfer</span>
                    }
                    {gotExternalAccounts === "Failed" &&
                        <div className="error-wrapper">
                            <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                            <div className="message-stack">
                                <span className="header-text boldLarge">Unable to load External Accounts</span>
                                <span className="sub-header-text regular">Please wait a moment and try again.</span>
                            </div>
                        </div>
                    }
                    <div className={!fromDetails ? "inner-card-wrap" : "inner-card-wrap details"}>
                        <TransfersDropDown
                            testId={"Transfer from dropdown"}
                            label="Transfer from"
                            isRequired={true}
                            defaultValue="Select an account"
                            selectedOption={selectedFromAccount}
                            setSelectedOption={setSelectedFromAccount}
                            selectOptions={selectionOptions}
                            className="small"
                        />
                        <TransfersDropDown
                            testId={"Transfer to dropdown"}
                            label="Transfer to"
                            isRequired={true}
                            defaultValue="Select an account"
                            selectedOption={selectedToAccount}
                            setSelectedOption={setSelectedToAccount}
                            removeOption={selectedFromAccount}
                            className="small"
                        />
                        <label className="label-wrap">
                            <div className="label-text-wrap">
                                <span className="amount-text small">Amount</span>
                                <span className="require-star">*</span>
                            </div>
                            <div className={isAmountErrored ? "input-box-wrap error" : "input-box-wrap"}>
                                {transferAmount && transferAmount > 0 && <span className="regular">$</span>}
                                <input
                                    className="input-box regular"
                                    type="number"
                                    placeholder="$0.00"
                                    value={transferAmount as number}
                                    onChange={(e: any) => amountChangeHandler(e)}
                                    onKeyDown={(e: any) => handleOnKeyDown(e)}
                                />
                            </div>
                            {amountInputError && <span className="error-text mobileSemiboldError">Entered Value Exceeds Available Account Balance</span>}
                            {maxTransferError && <span className="error-text mobileSemiboldError">An Individual Transfer Cannot Exceed $1,100,000</span>}
                            {maxAccountError && <span className="error-text mobileSemiboldError">Internal Savings accounts cannot exceed $1,500,000.</span>}
                        </label>
                        <label className="label-wrap">
                            <div className="label-text-wrap">
                                <span className="amount-text small">Transfer date</span>
                                <span className="require-star">*</span>
                            </div>
                            <div className={dateInputError ? "input-box-wrap error" : "input-box-wrap"}>
                                <input
                                    className="input-box regular"
                                    type="date"
                                    placeholder="Today"
                                    value={transferDate}
                                    onChange={(e: any) => dateChangeHandler(e)}
                                    min={minDate}
                                />
                            </div>
                            {dateInputError && <span className="error-text mobileSemiboldError">The Selected Date Cannot Be Less Than Today's Date</span>}
                        </label>
                        <div className="transfers-text-wrap">
                            <p className="transfers-text small">Transfers submitted after 5 p.m. Pacific time or on a non-business day will be scheduled for the next business day.</p>
                        </div>
                        {!fromDetails &&
                            <>
                                <div className="border" />
                                <div className="nav-wrap">
                                    <div className="nav" onClick={() => handleLinkExternalAccount()}>
                                        <img src={bankBlue} alt="Link external account" />
                                        <span className="nav-text regular">Link external account </span>
                                    </div>
                                    <div className="nav" onClick={() => { history.push('/user/profile/external-accounts', { page: "Transfers" }) }}>
                                        <img src={blueSettings} alt="Manage External Accounts" />
                                        <span className="nav-text regular">Manage external accounts</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {!fromDetails ?
                        <button className={enableContinue ? "Button btn-primary margin" : "Button btn-primary inactive margin"} onClick={() => handleContinue()}>Continue</button>
                        :
                        <button onClick={handleTransferFromSavingsDetails} className={enableContinue ? "Button btn-primary margin details" : "Button btn-primary inactive margin details"} >Make a transfer</button>
                    }
                </div>
            }
        </>
    )
}

export default CreateTransferLanding