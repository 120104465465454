import { useEffect, useRef, useState } from "react"
import { useTransactionHistoryContext } from '../../../Pages/TransactionHistory/context/TransactionHistoryContext'


export const useAmountFilter = (setAmountSelected?: React.Dispatch<React.SetStateAction<boolean>>) => {
    const { amountFilter: { minAmount, maxAmount } } = useTransactionHistoryContext();
    const minAmountInputRef = useRef(document.createElement('input'));
    const maxAmountInputRef = useRef(document.createElement('input'));
    const [shouldShowError, setShouldShowError] = useState(false);

    useEffect(() => {
        minAmountInputRef.current.value = minAmount.ref.get()?.toString() || '';
        maxAmountInputRef.current.value = maxAmount.ref.get()?.toString() || '';
    }, [minAmountInputRef, maxAmountInputRef])

    /**onChange handle for min input box */
    const onChangeMin = (e: any) => {

        if (e.nativeEvent.inputType === 'insertText' && e.nativeEvent.data === '-') {
            minAmountInputRef.current.value = '-0';
            return;
        }
        const NEW_VALUE = +e.target.value;
        minAmountInputRef.current.value = NEW_VALUE.toString()

        if (!Number.isNaN(NEW_VALUE)) {
            minAmount.ref.set(NEW_VALUE);
        }
    }

    /**onChange handle for max input box */
    const onChangeMax = (e: any) => {

        if (e.nativeEvent.inputType === 'insertText' && e.nativeEvent.data === '-') {
            maxAmountInputRef.current.value = '-0';

            return;
        }
        const NEW_VALUE = +e.target.value;
        maxAmountInputRef.current.value = NEW_VALUE.toString();
        if (!Number.isNaN(NEW_VALUE)) {
            maxAmount.ref.set(NEW_VALUE);
        }
    }

    /**
     * Checks if the inputs should show an error state.
     */
    const checkForMaxValidation = () => {
        if ((maxAmount.ref.get() || 0) < (minAmount.ref.get() || 0)) {
            setShouldShowError(true);
        }
        else {
            setShouldShowError(false);
        }

    }

    /**
     * Returns the class names for the input.
     */
    const getClasses = () => {
        const CLASSES = ['amount-input-wrap', 'regular'];

        if (minAmount.ref.get() !== null) {
            CLASSES.push('selected');
        }

        if (shouldShowError) {
            CLASSES.push('is-error');
        }

        return CLASSES.join(' ')
    }

    const handleOnMobileSave = () => {
        if ((minAmount.ref.get() || 0) > (maxAmount.ref.get() || 0)) {
            setShouldShowError(true);
            return;
        }

        setShouldShowError(false);

        minAmount.ref.set(+(minAmount.ref.get() as number).toFixed(2))

        if (!maxAmount.ref.get()) {
            maxAmount.ref.set(null);
        }
        else {
            maxAmount.ref.set(+(maxAmount.ref.get() as number).toFixed(2))

        }

        setAmountSelected?.(false);
    }

    const onMobileBack = () => {
        maxAmount.setStateValueToRef()
        minAmount.setStateValueToRef()
        setAmountSelected?.(false);
    }




    return {
        onChangeMax,
        onChangeMin,
        minAmountInputRef,
        maxAmountInputRef,
        getClasses,
        checkForMaxValidation,
        handleOnMobileSave,
        onMobileBack
    }

}