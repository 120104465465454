/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'
import NotificationsModal from '../../../../components/NotificationSettings/DesktopNotificationSettings/components/NotificationsModal/NotificationsModal'
import Toggle from '../../../../components/Toggle/Toggle'
import { useNotificationSettings } from '../../../../useHooks/componentHooks/useNotificationSettings/useNotificationSettings'
import MobileHeaderBox from '../MobileHeaderBox/MobileHeaderBox'
import { IMobileNotificationSettingsProps } from './interfaces/IMobileNotificationSettingsProps'
import './MobileNotificationSettings.scss'
import warning from '../../../../assets/warning.svg'
import { ToastMessageDB } from '../../../../shared/digitalbank/ToastMessage/ToastMessage'

/**Renders the mobile version of notification settings found in the user profile flow */
const MobileNotificationSettings = (props: IMobileNotificationSettingsProps) => {
    const { setCurrentTab } = props
    const { handleToggleClickOne, toggleOne, showModal, setShowModal, message, type } = useNotificationSettings()
    const gotSMS = useAppSelector((state: RootState) => state.userInformation.gotSMS)

    return (
        <div className="mobile-notification-settings-wrap">
            {message !== "" && <ToastMessageDB type={type} message={message} />}
            <MobileHeaderBox 
                setCurrentTab={setCurrentTab}
                title="Notification settings"
            />
            {gotSMS !== "Loading" ?
                <div className="info-card-wrap">
                    {gotSMS === "Success" ?
                        <>
                        <span className="card-title mobileh2">Notification settings</span>
                        <div className="data-row full-border">
                            <div className="top">
                                <span className="label-text boldRegular">Text notifications</span>
                                <Toggle 
                                    toggle={toggleOne}
                                    setToggle={handleToggleClickOne}
                                    fromNotifications={true}
                                />
                            </div>
                            <span className="info-text small">Receive account notifications by text message on your mobile phone. Standard service provider rates apply.</span>
                        </div>
                        </>
                        :
                        <div className="failed-wrapper">
                            <img src={warning} alt="unable to retreive data" />
                            <span className="header-text h4">Unable to load data</span>
                            <span className="sub-text regular">Please wait a moment and try again.</span>
                        </div>
                    }
                </div>
                :
                <div className="loading-box" />
            }
            {showModal &&
                <NotificationsModal 
                    setShowModal={setShowModal}
                    toggleStatus={toggleOne}
                />
            }
        </div>
    )
}

export default MobileNotificationSettings