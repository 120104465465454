import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { IAccountInformation } from "../../../api/getAccountInformation";
import { IBeneficiary } from "../../../api/getBeneficiaries";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { addBeneficiaryByIndex, setBeneficiaryDeletedFlagToFalseByIndex, updateBeneficiaryAllocationByIndex, updateBeneficiaryArrayByIndex } from "../../../slices/accountInformation/accountInformationSlice";
import { postNewBeneficiaryOrgAsync, postNewBeneficiaryPersonAsync, setBeneficiaryDeletedFlagToFalse, setUpdatedBeneficiaryArray } from "../../../slices/selectedAccountSlice/selectedAccountSlice";
import { useScrollToTop } from "../../ScrollToTopHook/useScrollToTop";
import { patchBeneficiaryAllocations_API } from '../../../api/getBeneficiaries'
import { useToastMessage } from "../../../Context/ToastContext/useToastContext";

/**
* the use hook is contains the logic needed by the Edit Beneficiary Allocation Page.
*/
export const useBeneficiaryEdit = (
        showAlertMessage: string, 
        setShowAlertMessage: any, 
        setEditBeneficiary: any, 
        childRef: any, 
        checkAllocations: any, 
        setCheckAllocations: any, 
        newBeneficiary: IBeneficiary,
        setOnDelete: React.Dispatch<React.SetStateAction<boolean>>,
        setShowDeleteConfirmationPage: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
    const [successfulAllocation, setSuccessfulAllocation] = useState<boolean>(false)
    const [totalPercentage, setTotalPercentage] = useState<number>(0)
    const [showAlertBox] = useState<boolean>(true)
    const adjustmentMade = useRef<boolean>(false)
    const history = useHistory()
    const accountInformation = useAppSelector((state: RootState) => state.accountInformation.accountInformation)
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const dispatch = useAppDispatch();
    const [editBeneficiaryArray, setEditBeneficiaryArray] = useState<Array<IBeneficiary>>(JSON.parse(JSON.stringify(selectedAccount.beneficiaries)))
    const [zeroValueError, setZeroValueError] = useState<boolean>(false)
    const [sumError, setSumError] = useState<boolean>(false)
    useScrollToTop()
    const { type, setType, message, setMessage, resetToast, setToastMessage } = useToastMessage()
    const isSaveClicked = useRef<boolean>(false)
    const [previousPage, setPreviousPage] = useState<string>();
    const apiStatusRef = useRef<true | false | null>(null)

    /**
    * Calculates the total percentage currently allocated and checks for input errors
    */
    const checkPercentages = (beneficiaryArray: Array<IBeneficiary>) => {
        let tempTotalPercentage: number = 0
        let inputError = false
        for (let i = 0; i < beneficiaryArray.length; i = i + 1) {
            //? having 1 beneficiary skips allocations, unreachable code?
            if (beneficiaryArray.length === 1 && !beneficiaryArray[i].isDeleted) {
                beneficiaryArray[i].percentage = 100
            }
            if (!beneficiaryArray[i].percentage) {
                beneficiaryArray[i].percentage = 0
                inputError = true;
                setZeroValueError(true)
            }
            if (!beneficiaryArray[i].isDeleted) {
                tempTotalPercentage = tempTotalPercentage + beneficiaryArray[i].percentage
            }
            if (beneficiaryArray[i].percentage <= 0 || beneficiaryArray[i].percentage > 100) {
                inputError = true
                setSumError(true)
            }
        }
        /**Checks for the edge case that only one beneficiary exists and the user wants to delete it */
        for (let i = 0; i < beneficiaryArray.length; i = i + 1) {
            //? unreachable code?
            if (beneficiaryArray[i].isDeleted && beneficiaryArray.length === 1) {
                inputError = false
                setZeroValueError(false)
                setSumError(false)
                setSuccessfulAllocation(true)
                return true
            }
        }
        setTotalPercentage(JSON.parse(JSON.stringify(tempTotalPercentage)))
        setCheckAllocations(false)
        if ((tempTotalPercentage === 100 && !inputError) || beneficiaryArray.length === 0) {
            setSuccessfulAllocation(true)
            setZeroValueError(false)
            setSumError(false)
            setEditBeneficiaryArray(beneficiaryArray)
            return true
        }
        else {
            setSuccessfulAllocation(false)
            return false
        }
    }

    /**
     * Checks the current allocations on render
     */
    useEffect(() => {
        if (editBeneficiaryArray !== selectedAccount.beneficiaries) {
            setEditBeneficiaryArray(JSON.parse(JSON.stringify(selectedAccount.beneficiaries)))
            checkPercentages(JSON.parse(JSON.stringify(selectedAccount.beneficiaries)))
        }
        const index = accountInformation.findIndex((account: any) => account.accountNumber === selectedAccount.accountNumber)
        //?the ELSE should never occur
        if (index >= 0) {
            if (accountInformation[index].beneficiaries !== selectedAccount.beneficiaries) {
                adjustmentMade.current = true
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    /**The useEffect checks the editBenefiicaryArray length, if it is 0 the if statement runs */
    useEffect(() => {
        if (!editBeneficiaryArray.length) {
            //? Useeffect only runs on allocations page, unreachable code?
            setTotalPercentage(JSON.parse(JSON.stringify(0)))
            // dispatch(setBeneficiaryTotalPercentage(0))
            setSuccessfulAllocation(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editBeneficiaryArray])

    /**
     * The funcation reads the user input and adjusts the indexed beneficiary's percentage
     */
    const onChangeHandler = (e: any, index: number) => {
        if (!adjustmentMade.current) {
            adjustmentMade.current = true
        }
        let tempArray = editBeneficiaryArray
        tempArray[index].percentage = parseFloat(e.target.value)
        setEditBeneficiaryArray(tempArray)
        checkPercentages(editBeneficiaryArray)
    }

    /**
     * If the user exits the application, clicks off, or clicks cancel we need to check the allocations
     * and present the user with a warning when it is not 100%
     */
    const checkAllocationsOnCancel = (origin: "Settings" | "Cancel") => {
        if (origin === "Settings") {
            setEditBeneficiary(false)
            /** Go(-2) here to go past the extra history entry caused by the window.history.pushState 
             * in the useEffect that checks for browser back buttons
             */
            // history.go(-2);
            history.goBack()
            // history.push()
        }
        else {
            setEditBeneficiary(false)
            // history.goBack();
        }
    }

    /**
     * Updates the real beneficiary array with the editBeneficiaryArray
     */
    const saveChanges = async (handleOnNext: any, onAddBeneficiary: boolean) => {
        apiStatusRef.current = true
        let tempNewBeneficiary = newBeneficiary
        let tempBeneficiaryArray = JSON.parse(JSON.stringify(editBeneficiaryArray));
        if (tempNewBeneficiary && tempNewBeneficiary.id !== "" && onAddBeneficiary) {
            if (!isSaveClicked.current) {
                //reconsider how new beneficiaries are saved in this section and then updated in the allocation section
                const apiPayload = { accountId: selectedAccount.id, beneficiary: tempNewBeneficiary, accountInformation: accountInformation }
                isSaveClicked.current = true
                if (tempNewBeneficiary.type === "Person") {
                    try {
                        const response: any = await dispatch(postNewBeneficiaryPersonAsync(apiPayload))
                        if (response.error) {
                            errorHanding(response.error);
                            return;
                        }

                        const payload = createBeneficiary(response);
                        const TEMP_BENEFICIARY_INDEX = tempBeneficiaryArray.findIndex((e: any) => e.id === tempNewBeneficiary.id);
                        if (TEMP_BENEFICIARY_INDEX !== -1) {
                            response.payload.isDeleted = false;
                            tempBeneficiaryArray[TEMP_BENEFICIARY_INDEX].id = response.payload.id;
                            payload.beneficiary.id = response.payload.id;
                        }
                        try {
                            await dispatch(setUpdatedBeneficiaryArray(tempBeneficiaryArray)) //new
                            await dispatch(addBeneficiaryByIndex(payload))
                            await updateBeneficiaryAllocation(tempBeneficiaryArray, handleOnNext, onAddBeneficiary)
                        }
                        //? unreachable due to try/catch inside updateBeneficiaryAllocation?
                        catch (err) {
                            //if allocations call failed
                            deleteBeneficiary();

                        }
                        apiStatusRef.current = false
                    }
                    //? unreachable due to return in if(response.error)
                    catch (err) {
                        errorHanding(err);
                    }
                }
                else {
                    try {
                        const response: any = await dispatch(postNewBeneficiaryOrgAsync(apiPayload))
                        if (response.error) {
                            errorHanding(response.error);
                            return;
                        }



                        const payload = createBeneficiary(response);
                        const TEMP_BENEFICIARY_INDEX = tempBeneficiaryArray.findIndex((e: any) => e.id === tempNewBeneficiary.id);

                        if (TEMP_BENEFICIARY_INDEX !== -1) {
                            tempBeneficiaryArray[TEMP_BENEFICIARY_INDEX].id = response.payload.id;
                            payload.beneficiary.id = response.payload.id;
                        }
                        try {
                            setEditBeneficiaryArray(tempBeneficiaryArray);
                            await updateBeneficiaryAllocation(tempBeneficiaryArray, handleOnNext, onAddBeneficiary)
                            await dispatch(setUpdatedBeneficiaryArray(tempBeneficiaryArray))
                            // await dispatch(addBeneficiaryByIndex(payload))

                        }
                        //? unreachable due to try/catch inside updateBeneficiaryAllocation?
                        catch (err) {
                            //if allocations call failed
                            deleteBeneficiary();
                        }
                    }
                    //? unreachable due to return in if(response.error)
                    catch (err) {
                        console.log("Encountered an error adding the new beneficiary org", err);
                        errorHanding(err);
                    }
                    apiStatusRef.current = false
                }
            }
        }
        else {
            if (!isSaveClicked.current) {
                updateBeneficiaryAllocation(tempBeneficiaryArray, handleOnNext, false)
                isSaveClicked.current = true;
            }
        }

        /**
         * Displays toast message
         */
        function errorHanding(err: any) {
            setToastMessage("ERROR", "Unable to save changes. Please try again later.");
            resetToast();
            console.log('errorHandling', err);
            isSaveClicked.current = false;
        }

        /**
         * Creates the new beneficiary and updates the allocations
         * @param response 
         * @returns payload
         */
        function createBeneficiary(response: any) {
            //Creates the new beneficary
            const index = tempBeneficiaryArray.findIndex((beneficiary: IBeneficiary) => beneficiary.id === tempNewBeneficiary.id);
            tempNewBeneficiary = { ...tempNewBeneficiary, id: response.payload.id, percentage: tempBeneficiaryArray[index].percentage };
            tempBeneficiaryArray[index] = tempNewBeneficiary;

            //Updates the allocations
            const index2 = accountInformation.findIndex((account: any) => account.accountNumber === selectedAccount.accountNumber);
            const payload = { arrayIndex: index2, beneficiary: tempNewBeneficiary };
            return payload;
        }

        /**
         * Deletes the newly created beneficiary if Allocations API call fails
         */
        function deleteBeneficiary() {
            const PREV_ACCOUNT = accountInformation.find(e => e.id === selectedAccount.id);

            if (PREV_ACCOUNT) {
                const BENE: Array<any> = PREV_ACCOUNT.beneficiaries.map(e => ({ id: e.id, percentage: e.percentage, isDeleted: false }));
                BENE.push({ id: tempNewBeneficiary.id, percentage: 1, isDeleted: true });
                patchBeneficiaryAllocations_API(selectedAccount.id, BENE, accountInformation);

                errorHanding('');
            }
        }
        // apiStatusRef.current = false
        // Promise.resolve(true)
        return new Promise((resolve) => {
            let interval: any
            interval = setInterval(() => {
                if (apiStatusRef.current === false) {
                    apiStatusRef.current = null
                    resolve(true)
                    clearInterval(interval)
                }
            }, 100)
        })
    }

    /**Populate the new array */
    const populateNewArray = (tempBeneficiaryArray: Array<IBeneficiary>) => {
        let array: Array<IBeneficiary> = []
        for(let i = 0; i < tempBeneficiaryArray.length; i = i + 1){
            let temp = {
                address1: tempBeneficiaryArray[i].address1,
                address2: tempBeneficiaryArray[i].address2,
                city: tempBeneficiaryArray[i].city,
                dateOfBirth: tempBeneficiaryArray[i].dateOfBirth,
                email: tempBeneficiaryArray[i].email,
                firstName: tempBeneficiaryArray[i].firstName,
                id: tempBeneficiaryArray[i].id,
                isDeleted: tempBeneficiaryArray[i].isDeleted,
                middleName: tempBeneficiaryArray[i].middleName,
                lastName: tempBeneficiaryArray[i].lastName,
                name: tempBeneficiaryArray[i].name,
                percentage: tempBeneficiaryArray[i].percentage,
                phoneNumber: tempBeneficiaryArray[i].phoneNumber,
                relationship: tempBeneficiaryArray[i].relationship,
                ssn: tempBeneficiaryArray[i].ssn,
                state: tempBeneficiaryArray[i].state,
                type: tempBeneficiaryArray[i].type,
                zipCode: tempBeneficiaryArray[i].zipCode,
                trustName: tempBeneficiaryArray[i].trustName,
                trustEstablishedDate: tempBeneficiaryArray[i].trustEstablishedDate,
                charityName: tempBeneficiaryArray[i].charityName,
                tin: tempBeneficiaryArray[i].tin
            }
            array.push(temp)
        }
        
        return array
    }

    /**Update the beneficiary allocations after adding a new beneficiary */
    const updateBeneficiaryAllocation = async (tempBeneficiaryArray: Array<IBeneficiary>, handleOnNext: any, onAddBeneficiary: boolean) => {
        let foundDeletedBeneficiary: boolean = false
        const NEW_ARRAY = JSON.parse(JSON.stringify(populateNewArray(tempBeneficiaryArray)))
        try {
            await patchBeneficiaryAllocations_API(selectedAccount.id, NEW_ARRAY, accountInformation)
            for (let i = 0; i < NEW_ARRAY.length; i = i + 1) {
                if (NEW_ARRAY[i].isDeleted) {
                    NEW_ARRAY.splice(i, 1)
                    foundDeletedBeneficiary = true
                }
            }
            setEditBeneficiaryArray(NEW_ARRAY)
            /**Updates the Selected Account */
            dispatch(setUpdatedBeneficiaryArray(NEW_ARRAY))
            let accountIndex = accountInformation.findIndex((account: IAccountInformation) => account.id === selectedAccount.id)
            const payload = { arrayIndex: accountIndex, beneficiaryArray: NEW_ARRAY }
            /**Updates the Account Information Array */
            if(!onAddBeneficiary){
                dispatch(updateBeneficiaryArrayByIndex(payload))
                dispatch(setUpdatedBeneficiaryArray(NEW_ARRAY))
            }
            else{
                dispatch(updateBeneficiaryArrayByIndex(payload))
            }

            if (onAddBeneficiary) {
                handleOnNext()
            }
            else {
                if (!foundDeletedBeneficiary) {
                    setToastMessage('SUCCESS', "Allocations updated")
                    resetToast()
                    setEditBeneficiary(false)
                }
                else {
                    setShowDeleteConfirmationPage(true)
                }
            }
            apiStatusRef.current = false
            // return Promise.resolve(true);
        }
        catch {
            console.log("ERRORED UPDATING THE BENEFICIARY ALLOCATIONS")
            setToastMessage('ERROR', "Unable to save changes. Please try again later.")
            resetToast()
            apiStatusRef.current = false
        }
        return new Promise((resolve) => {
            let interval: any
            interval = setInterval(() => {
                if (apiStatusRef.current === false) {
                    apiStatusRef.current = null
                    resolve(true)
                    clearInterval(interval)
                }
            }, 100)
        })
    }

    /**
     * The useEffect is designed to create an document event listener that will watch for any mouse clicks outside of the beneficiary allocation
     * area.  If the allocations are not 100% it will prompt the user with the unsaved changes modal.
     */
    // useEffect(() => {
    //     const handleClickOutside = (event: any) => {
    //         if (childRef.current && !childRef.current.contains(event.target)) {
    //             let success = checkPercentages(editBeneficiaryArray)
    //             if (!success || adjustmentMade.current) {
    //                 event.stopPropagation()
    //                 checkAllocationsOnCancel("Cancel")
    //             }
    //         }
    //     }
    //     document.addEventListener('click', handleClickOutside, true)
    //     return () => document.removeEventListener('click', handleClickOutside, true)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    /**
     * The useEffect is designed to create a window event listener that checks for unsaved changes in the event
     * that the user presses the browser 'back' button.
     */
    // useEffect(() => {
    //     //? is it possible to test this?
    //     const onBackButtonEvent = (event: any) => {
    //         event.preventDefault()
    //         let success = checkPercentages(editBeneficiaryArray)
    //         if (!success || adjustmentMade.current) {
    //             window.confirm("Please save your changes before leaving the page.")
    //             window.history.pushState(null, "", window.location.pathname);
    //         }
    //     }
    //     window.history.pushState(null, "", window.location.pathname);
    //     window.addEventListener('popstate', onBackButtonEvent);
    //     return () => {
    //         window.removeEventListener('popstate', onBackButtonEvent);
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    /**
     * Sets the state of previousPage, depending on if there is a `page` state pushed in from history.push
     * This is used to dynamically set the text of the back button on certain pages
     */
    useEffect(() => {
        if (history.location.state !== undefined && history.location.state !== null) {
            const STATE: { page: string } = history.location.state as { page: string }
            setPreviousPage(STATE.page)
        };
        //eslint disabling on the next line because we only want to run this useEffect whenever history.location.state is updated. Which should only be once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.state]);

    const resetBeneficiaryArray = (onRemove: boolean = false) => {
        let accountIndex = accountInformation.findIndex((account) => account.accountNumber === selectedAccount.accountNumber);

        //Remove the is deleted from the one beneficiary
        if(onRemove){
            for(let i = 0; i < selectedAccount.beneficiaries.length; i = i + 1){
                if(selectedAccount.beneficiaries[i].isDeleted){
                    dispatch(setBeneficiaryDeletedFlagToFalse(i))
                    const payload = {arrayIndex: accountIndex, index: i}
                    dispatch(setBeneficiaryDeletedFlagToFalseByIndex(payload))
                }
            }
            setEditBeneficiaryArray(selectedAccount.beneficiaries);
        }
        else{
            dispatch(setUpdatedBeneficiaryArray(accountInformation[accountIndex].beneficiaries));
            setEditBeneficiaryArray(accountInformation[accountIndex].beneficiaries);
        }

        adjustmentMade.current = false;
    }

    /**
     * The use effect keeps track of the showAlertMessage state. If the state changes we check the state,
     * if it is not "" then we start a timer for 5 seconds, and then set it to "".  This will control
     * the alert box animation.
     */
    //  useEffect(() => {
    //     if(showAlertMessage !== ""){
    //         checkPercentages(editBeneficiaryArray)
    //         setTimeout(() => {
    //             setShowAlertBox(false)
    //         },3000)
    //         setTimeout(() => {
    //             setShowAlertMessage("")
    //         },3500)
    //     }
    //     else {
    //         setShowAlertBox(true)
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [showAlertMessage])

    return {
        successfulAllocation, editBeneficiaryArray, resetBeneficiaryArray, onChangeHandler, totalPercentage, saveChanges, showAlertBox, accountInformation,
        checkAllocationsOnCancel, selectedAccount, zeroValueError, sumError, type, message, previousPage
    }
}; 
