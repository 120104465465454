import { useTransferReview } from '../../../../useHooks/componentHooks/useTransferReview/useTransferReview'
import { ITransferReviewProps } from './interfaces/ITransferReviewProps'
import './TransferReview.scss'
import accountBadge from '../../../../assets/accountBadge.svg'
import chartArrowRight from '../../../../assets/chartArrowRight.svg'
import { displayCurrency } from '../../../../utils/displayCurrency'
import arrowLeftBlue from '../../../../assets/arrowLeftBlue.svg'
import chartArrowDown from '../../../../assets/chartArrowDown.svg'
import redExclamationCircle from '../../../../assets/redExclamationCircle.svg'
import { convertDateTommddyyyy } from '../../../../utils/DateUtils'
import { Button, TIMER_IDS } from '../../../../components/Button/Button'
import { useWindowState } from '../../../../Context/AccountContext/useWindowState'
// import { useWindowState } from '../../../../Context/AccountContext/useWindowState'

/**Renders the Transfer Review page found in the Create New Transfer flow on Transfers page */
const TransferReview = (props: ITransferReviewProps) => {
    const { selectedFromAccount, selectedToAccount, transferAmount, transferDate, topCardIndex, setTopCardIndex, scheduledTransfers, setScheduledTransfers } = props
    const { windowState, formatedTransferDate, handleConfirmClick, amount, todaysDate, createTransferStatus } = useTransferReview(transferDate, transferAmount as number, setScheduledTransfers, scheduledTransfers)

    return (
        <>
            {windowState !== "Mobile" ?
                <TransferReviewDesktop
                    createTransferStatus={createTransferStatus}
                    selectedFromAccount={selectedFromAccount}
                    selectedToAccount={selectedToAccount}
                    amount={amount}
                    formatedTransferDate={formatedTransferDate}
                    todaysDate={todaysDate}
                    transferDate={transferDate}
                    setTopCardIndex={setTopCardIndex}
                    handleConfirmClick={handleConfirmClick}
                    topCardIndex={topCardIndex}
                />
                :
                <TransferReviewMobile
                    createTransferStatus={createTransferStatus}
                    selectedFromAccount={selectedFromAccount}
                    selectedToAccount={selectedToAccount}
                    amount={amount}
                    formatedTransferDate={formatedTransferDate}
                    todaysDate={todaysDate}
                    transferDate={transferDate}
                    setTopCardIndex={setTopCardIndex}
                    handleConfirmClick={handleConfirmClick}
                    topCardIndex={topCardIndex}
                />
            }
        </>
    )
}

const TransferReviewDesktop = (props: any) => {
    const {
        createTransferStatus,
        selectedFromAccount,
        selectedToAccount,
        amount,
        formatedTransferDate,
        todaysDate,
        transferDate,
        setTopCardIndex,
        handleConfirmClick,
        topCardIndex
    } = props;

    return <div className="transfer-review-wrap">
        <div className="title-wrap">
            <p className="title-text h2">Make a transfer</p>
            <p className="sub-title-text regular">Transfer money between your Credit One or external accounts.</p>
        </div>
        {createTransferStatus === "Fail" &&
            <div className="error-wrapper">
                <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                <div className="message-stack">
                    <span className="header-text boldLarge">Sorry, we’re having trouble making your transfer</span>
                    <span className="sub-header-text regular">Please wait a moment and try again.</span>
                </div>
            </div>
        }
        <TransferReviewContainer
            selectedFromAccount={selectedFromAccount}
            selectedToAccount={selectedToAccount}
            amount={amount}
            formatedTransferDate={formatedTransferDate}
            transferDate={transferDate}
            todaysDate={todaysDate}
        />
        <div className="buttons-wrap">
            <div className="left-side" onClick={() => setTopCardIndex(0)}>
                <img className="back-arrow" src={arrowLeftBlue} alt="back" />
                <span className="edit-transfer-text regular">Edit transfer</span>
            </div>
            <Button
                title="Confirm"
                className="Button btn-primary"
                id={TIMER_IDS.TRANSFER_CREATE}
                onClick={() => handleConfirmClick(selectedFromAccount, selectedToAccount, topCardIndex, setTopCardIndex)}
            />
        </div>
    </div>
}

const TransferReviewMobile = (props: any) => {
    const {
        createTransferStatus,
        selectedFromAccount,
        selectedToAccount,
        amount,
        formatedTransferDate,
        todaysDate,
        transferDate,
        setTopCardIndex,
        handleConfirmClick,
        topCardIndex
    } = props;

    return <div className="transfer-review-wrap">
        <div className="title-wrap">
            <p className="title-text h2">Make a transfer</p>
            <p className="sub-title-text regular">Transfer money between your Credit One or external accounts.</p>
        </div>
        {createTransferStatus === "Fail" &&
            <div className="error-wrapper">
                <img className="image" src={redExclamationCircle} alt="red circle exclamation mark" />
                <div className="message-stack">
                    <span className="header-text boldLarge">Sorry, we’re having trouble making your transfer</span>
                    <span className="sub-header-text regular">Please wait a moment and try again.</span>
                </div>
            </div>
        }
        <TransferReviewContainer
            selectedFromAccount={selectedFromAccount}
            selectedToAccount={selectedToAccount}
            amount={amount}
            formatedTransferDate={formatedTransferDate}
            transferDate={transferDate}
            todaysDate={todaysDate}
        />
        <div className="buttons-wrap">
            <div className="left-side" onClick={() => setTopCardIndex(0)}>
                <img src={arrowLeftBlue} alt="back" />
                <span className="edit-transfer-text regular">Edit transfer</span>
            </div>
            <Button
                title="Confirm"
                className="Button btn-primary"
                id={TIMER_IDS.TRANSFER_CREATE}
                onClick={() => handleConfirmClick(selectedFromAccount, selectedToAccount, topCardIndex, setTopCardIndex)}
            />
        </div>
    </div>
}

const TransferReviewContainer = (props: any) => {
    const { selectedFromAccount, selectedToAccount, amount, formatedTransferDate, transferDate, todaysDate } = props;
    const { windowState } = useWindowState();

    const isMobile = windowState === 'Mobile' ? 'mobile' : ''
    const boldSmall = windowState === 'Mobile' ? 'mobileBoldSmall' : 'boldSmall';
    const regular = windowState === 'Mobile' ? 'regular' : 'small'
    const small = windowState === 'Mobile' ? 'x-small' : 'small';

    return <div className={`inner-data-wrap ${isMobile}`}>
        <span className="inner-title h4">Review transfer detail</span>
        <div className={`transfer-accounts-row ${isMobile}`}>
            <div className={`transfer-wrap ${isMobile}`}>
                <span className={`wrap-text ${boldSmall}`}>Transfer from</span>
                <div className="transfer-row">
                    <img src={accountBadge} alt="badge" />
                    <div className="data-stack">
                        <span className={`account-title ${regular}`}>{selectedFromAccount.title}</span>
                        <span className={`account-info-text ${small}`}>{selectedFromAccount.accountType.toLowerCase() !== "external" ? selectedFromAccount.type : "External"} {selectedFromAccount.lastFour}</span>
                    </div>
                </div>
            </div>
            <img src={isMobile ? chartArrowDown : chartArrowRight} alt="down arrow" />
            <div className={`transfer-wrap ${isMobile}`}>
                <span className={`wrap-text ${boldSmall}`}>Transfer to</span>
                <div className="transfer-row">
                    <img src={accountBadge} alt="badge" />
                    <div className="data-stack">
                        <span className={`account-title ${regular}`}>{selectedToAccount.title}</span>
                        <span className={`account-info-text ${small}`}>{selectedToAccount.accountType.toLowerCase() !== "external" ? selectedToAccount.type : "External"} {selectedToAccount.lastFour}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="label-value-wrap">
            <span className="text mobileBoldSmall">Amount</span>
            <span className="text regular">{displayCurrency(+parseFloat(amount).toFixed(2))}</span>
        </div>
        <div className="label-value-wrap">
            <span className="text mobileBoldSmall">Transfer date</span>
            <span className="text regular">{convertDateTommddyyyy(formatedTransferDate)} {transferDate === todaysDate ? " (Today)" : ""}</span>
        </div>
    </div>
}

export default TransferReview
